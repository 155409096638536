<script setup lang="ts"></script>

<template>
  <router-link to="/dashboard/password" class="button-password"
    ><svg viewBox="-66 0 512 512" id="acppass" class="svg-password">
      <path
        d="M 340 197.007812 L 340 150 C 340 67.289062 272.710938 0 190 0 C 107.289062 0 40 67.289062 40 150 L 40 197.007812 C 17.203125 201.652344 0 221.855469 0 246 L 0 302.769531 C 0 308.292969 4.476562 312.769531 10 312.769531 C 15.523438 312.769531 20 308.292969 20 302.769531 L 20 246 C 20 229.457031 33.457031 216 50 216 L 330 216 C 346.542969 216 360 229.457031 360 246 L 360 462 C 360 475.035156 351.640625 486.152344 340 490.28125 L 340 430 C 340 424.476562 335.523438 420 330 420 C 324.476562 420 320 424.476562 320 430 L 320 492 L 50 492 C 33.457031 492 20 478.542969 20 462 L 20 383 C 20 377.476562 15.523438 373 10 373 C 4.476562 373 0 377.476562 0 383 L 0 462 C 0 489.570312 22.429688 512 50 512 L 330 512 C 357.570312 512 380 489.570312 380 462 L 380 246 C 380 221.855469 362.796875 201.652344 340 197.007812 Z M 114 196 L 114 150 C 114 108.09375 148.09375 74 190 74 C 231.90625 74 266 108.09375 266 150 L 266 196 Z M 286 196 L 286 150 C 286 97.066406 242.933594 54 190 54 C 137.066406 54 94 97.066406 94 150 L 94 196 L 60 196 L 60 150 C 60 78.316406 118.316406 20 190 20 C 261.683594 20 320 78.316406 320 150 L 320 196 Z M 286 196 "
        style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
      ></path>
      <path
        d="M 10 352.488281 C 12.628906 352.488281 15.210938 351.421875 17.070312 349.558594 C 18.929688 347.699219 20 345.121094 20 342.488281 C 20 339.859375 18.929688 337.277344 17.070312 335.421875 C 15.210938 333.558594 12.628906 332.488281 10 332.488281 C 7.371094 332.488281 4.789062 333.558594 2.929688 335.421875 C 1.070312 337.277344 0 339.859375 0 342.488281 C 0 345.121094 1.070312 347.699219 2.929688 349.558594 C 4.789062 351.421875 7.371094 352.488281 10 352.488281 Z M 10 352.488281 "
        style="stroke: none; fill-rule: nonzero; fill-opacity: 1"
      ></path>
      <path
        d="M 190 458 C 206.542969 458 220 444.542969 220 428 L 220 374.898438 C 235.023438 364.953125 244.128906 348.234375 244.128906 329.914062 C 244.128906 300.140625 219.902344 275.914062 190.128906 275.914062 C 160.351562 275.914062 136.128906 300.140625 136.128906 329.914062 C 136.128906 348.101562 145.132812 364.757812 160 374.726562 L 160 428 C 160 444.542969 173.457031 458 190 458 Z M 156.128906 329.914062 C 156.128906 311.164062 171.378906 295.914062 190.128906 295.914062 C 208.875 295.914062 224.128906 311.164062 224.128906 329.914062 C 224.128906 342.789062 216.980469 354.410156 205.476562 360.25 C 202.117188 361.953125 200 365.398438 200 369.167969 L 200 428 C 200 433.515625 195.515625 438 190 438 C 184.484375 438 180 433.515625 180 428 L 180 369.035156 C 180 365.292969 177.910156 361.863281 174.582031 360.148438 C 163.199219 354.28125 156.128906 342.695312 156.128906 329.914062 Z M 156.128906 329.914062 "
        style="stroke: none; fill-rule: nonzero"
      ></path>
      <path
        d="M 330 399 C 332.640625 399 335.210938 397.929688 337.070312 396.070312 C 338.929688 394.210938 340 391.640625 340 389 C 340 386.359375 338.929688 383.789062 337.070312 381.929688 C 335.210938 380.070312 332.640625 379 330 379 C 327.371094 379 324.789062 380.070312 322.929688 381.929688 C 321.070312 383.789062 320 386.371094 320 389 C 320 391.628906 321.070312 394.210938 322.929688 396.070312 C 324.789062 397.929688 327.371094 399 330 399 Z M 330 399 "
        style="stroke: none; fill-rule: nonzero"
      ></path>
    </svg>
    <div><p class="text-password">Mot de passe</p></div>
  </router-link>
</template>

<style scoped>
.button-password {
  @apply flex items-center w-full p-2 h-16 border gap-3;
}
.svg-password {
  @apply pr-2 w-10 fill-lime-eronor border-r;
}
.text-password {
  @apply font-semibold;
}
.button-password:hover,
.button-password:hover .svg-password,
.button-password:hover .text-password {
  @apply border-lime-eronor text-lime-eronor;
}
@media (min-width: 900px) {
  .button-password {
    @apply h-20;
  }
  .svg-password {
    @apply w-12;
  }
}
</style>
