import { languageIdBus } from '@/event/languageIdBus'
import { getOptionName } from './getOptionName'
import { selectionsBus } from '@/event/product/selectionsBus'
import { getOptionNameForBasket } from './getOptionNameForBasket'

export const isSelected = (valueId: number, option: any) => {
  const optionName = getOptionNameForBasket(option, languageIdBus.value)

  return selectionsBus.value[optionName]?.includes(valueId)
}
