import { URL_BACK } from '@/config/constants'
import { firstPartUrl } from '@/event/url/url'

export const urlGetStore = 'v1/store/search_ssl'
export const urlGetProduct = 'v1/product/search_product'

export const urlFindAddress = 'v1/address/get_by_user'
export const urlAddressModify = 'v1/address/modify'
export const urlAddressAdd = 'v1/address/add'
export const urlAddressDelete = 'v1/address/delete'

export const urlOrderUpdateCancel = 'v1/order/update-cancel'
export const urlOrderAdd = 'v1/order/add'
export const urlOrderFind = 'v1/order/find'

export const urlCustomerCancelDelete = 'v1/customer/cancel_delete'
export const urlCustomerAdd = 'v1/customer/add'
export const urlCustomerFind = 'v1/customer/find'
export const urlConfirmRegister = 'v1/customer/confirm-register'
export const urlCustomerUpdatePassword = 'v1/customer/update-password'
export const urlCustomerGetOtp = 'v1/customer/get-otp'
export const urlCustomerVerifyOtp = 'v1/customer/verify-otp'
export const urlCustomerUpdate = 'v1/customer/update'
export const urlCustomerDelete = 'v1/customer/delete'
export const urlCustomerVerify = 'v1/customer/verify'
export const urlCustomerModifyPassword = 'v1/customer/modify-password'
export const urlCustomerLogin = 'v1/customer/login'
export const urlCustomerForgetPassword = 'v1/customer/forget-password'

export const urlJwtRefreshToken = 'v1/refresh-token'

export const urlCsrfToken = `${firstPartUrl}://${URL_BACK}/api/v1/csrf-token`

export const urlStripeCreateCheckout = 'v1/stripe/create-checkout-session'
