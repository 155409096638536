import { urlCsrfToken } from '@/api/endpoint'
import { ref } from 'vue'

export async function fetchCsrf(csrfToken: { value: string | null }) {
  let response

  try {
    const responseCsrf = await fetch(urlCsrfToken, {
      method: 'GET',
      credentials: 'include'
    })
    response = await responseCsrf.json()

    csrfToken.value = response.token
  } catch (e) {
    return
  }
}
