<script setup lang="ts">
import ButtonBack from '@/components/button/ButtonBack.vue'
import ButtonClose from '@/components/button/ButtonClose.vue'
import { mapUrl } from '@/storage/mapUrl'
import { configAddress } from '@/storage/setting/configAddress'
import { configEmail } from '@/storage/setting/configEmail'
import { configTaxNumber } from '@/storage/setting/configTaxNumber'
import { configTelephone } from '@/storage/setting/configTelephone'
import stores from '@/storage/stores'
import { toggleShowConditionUseModal } from '@/utils/toggle/information/toggleShowConditionUseModal'
import { computed } from 'vue'

const storeName = computed(() => stores.getStoresData().name)
const hostname = window.location.hostname
const dataConditionUse = computed(() => stores.getStoresData().condition_use)
const LastDateModified = computed(() => {
    if (!dataConditionUse.value || dataConditionUse.value.length === 0) {
        return ''
    }

    const latestDate = dataConditionUse.value.reduce((latest, current) => {
        const currentDate = new Date(current.date_modified)
        return currentDate > latest ? currentDate : latest
    }, new Date(0))

    return latestDate.toLocaleDateString('fr-FR', {
        // weekday: 'long', // Jour de la semaine en toutes lettres
        day: 'numeric', // Jour du mois sous forme numérique
        month: 'long', // Mois en toutes lettres
        year: 'numeric' // Année sous forme numérique
    })
})

const formattedText = (text: string) => {
    // const siret = "12345678910"
    const typeCook = 'fastfood'
    let updatedText = text

    updatedText = updatedText.replace(
        '[name_site_with_url]',
        `<a href="http://${hostname}" class="text-lime-eronor hover:underline">https://${hostname}</a>`
    )
    if (storeName.value)
        updatedText = updatedText.replace(
            '[name_restaurant]',
            `<span class="text-lime-eronor font-semibold">${storeName.value}</span>`
        )

    updatedText = updatedText.replace(
        '[number_siret]',
        `<span class="font-semibold text-gray-300">${configTaxNumber.value}</span>`
    )
    updatedText = updatedText.replace(
        '[address_restaurant]',
        `<a href="${mapUrl.value}" class="text-lime-eronor hover:underline" target="_blank">${configAddress.value}</a>`
    )
    updatedText = updatedText.replace('[type_cook]', typeCook)
    updatedText = updatedText.replace(
        '[number_phone]',
        `<a href="tel:${configTelephone.value}" class="text-lime-eronor hover:underline">${configTelephone.value}</a>`
    )
    updatedText = updatedText.replace(
        '[email_restaurant]',
        `<a href="mailto:${configEmail.value}" class="text-lime-eronor hover:underline">${configEmail.value}</a>`
    )
    return updatedText
}
</script>

<template>
    <section class="section-condition-use-modal">
        <div class="w-full flex justify-end">
            <ButtonClose :function="toggleShowConditionUseModal" :hover-color="'lime'" />
        </div>
        <section>
            <div class="div-h2-condition-use-modal">
                <h2 class="h2-condition-use-modal">Conditions générales d'utilisation</h2>
            </div>
            <section class="section-content-condition-use-modal">
                <div class="div-first-content-condition-use-modal">
                    Dernière mise a jour :
                    <span class="span-date-condition-use-modal">{{ LastDateModified }}</span>
                </div>

                <div class="div-header-content-condition-use-modal">
                    <p>
                        Les présentes conditions générales d’utilisation (CGU) régissent
                        l’utilisation du site https://{{ hostname }} et de ses services. En accédant
                        à ce site et en l’utilisant, vous acceptez d'être lié par les présentes
                        conditions. Si vous n’acceptez pas ces termes, veuillez ne pas utiliser
                        notre site.
                    </p>
                </div>

                <section class="section-second-content-privacy-policy-modal">
                    <div
                        v-for="(cu, index) in dataConditionUse"
                        :key="index"
                        class="flex flex-col gap-2"
                    >
                        <h3 class="h3-second-content-condition-use-modal">
                            {{ index + 1 + '. ' + cu.title }}
                        </h3>
                        <p v-html="formattedText(cu.content)"></p>
                    </div>
                </section>
            </section>
        </section>
    </section>
</template>

<style scoped>
.section-condition-use-modal {
    @apply absolute -top-32 rounded-md mb-2 z-30 w-full flex flex-col items-center px-4 gap-2 py-3 bg-slate-eronor;
}

.div-h2-condition-use-modal {
    @apply w-full flex justify-center;
}

.h2-condition-use-modal {
    @apply font-bold text-2xl;
}

.section-content-condition-use-modal {
    @apply py-3 flex flex-col gap-2;
}

.div-first-content-condition-use-modal {
    @apply w-full pt-4 font-semibold;
}

.span-date-condition-use-modal {
    @apply font-bold text-lime-eronor;
}

.div-header-content-condition-use-modal {
    @apply whitespace-pre-wrap;
}

.section-second-content-privacy-policy-modal {
    @apply w-full flex flex-col gap-3 pt-2;
}

.h3-second-content-condition-use-modal {
    @apply font-semibold underline;
}

@media screen and (min-width: 1400px) {
    .section-condition-use-modal {
        @apply max-w-[55rem];
    }
}
</style>
