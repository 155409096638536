import { computed } from 'vue'
import stores from '../stores'

export const configMetaTitle = computed(() => {
  const setting = stores.state.storeData.setting?.find(
    (s: { key: string }) => s.key === 'config_meta_title'
  )

  return setting?.value || null
})
