import customer from '@/storage/customer'
import { fetchPostAuthorization } from '../fetch/fetchPostAuthorization'
import address from '@/storage/address'
import { urlFindAddress } from '@/api/endpoint'

export async function fetchAddress() {
  const data = {
    customer_id: customer.getCustomerData().customer_id
  }

  try {
    const res = await fetchPostAuthorization(urlFindAddress, data)

    if (res.class == 'success') address.setAddressData(res.message)
  } catch (e) {}
}
