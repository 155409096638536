import { categoryIdBus } from '@/event/category/categoryIdBus'
import { productBus } from '@/event/product/productBus'
import { productIdBus } from '@/event/product/productIdBus'
import { relatedGroupBus } from '@/event/product/relatedGroupeBus'
import router from '@/router'
import products from '@/storage/products'
import { useRouter } from 'vue-router'

export const updateProduct = () => {
  const foundProduct = products.state.productData.find(
    (product: { category_id: number; product_id: number }) =>
      product.category_id === Number(categoryIdBus.value) &&
      product.product_id === Number(productIdBus.value)
  )
  if (!foundProduct) {
    router.push('/')
  }

  productBus.value = foundProduct || null
  relatedGroupBus.value = []
}
