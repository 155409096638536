import { getCurrentStoreId } from '@/storage/stores'

export function isRefreshTokenExpired(refreshToken: string) {
  if (!refreshToken) return true

  const parts = refreshToken.split('.')
  if (parts.length !== 3) {
    return true
  }

  const payloadBase64 = parts[1]
  const payload = JSON.parse(atob(payloadBase64))

  const currentTime = Math.floor(Date.now() / 1000)
  const currentStoreId = getCurrentStoreId()

  if (payload.exp < currentTime) {
    return true
  }

  if (Number(payload.storeId) !== Number(currentStoreId)) {
    return true
  }

  return false
}
