<script setup lang="ts"></script>

<template>
  <div class="div-logo">
    <svg class="svg-fb" viewBox="0 0 603 600" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M301.205 0C135.542 0 0 135.241 0 301.808C0 452.41 110.241 577.41 254.217 600V389.157H177.711V301.808H254.217V235.241C254.217 159.638 299.096 118.072 368.072 118.072C400.903 118.072 435.241 123.795 435.241 123.795V198.193H397.289C359.939 198.193 348.192 221.385 348.192 245.181V301.808H431.927L418.373 389.157H348.192V600C419.168 588.789 483.801 552.575 530.418 497.892C577.036 443.211 602.572 373.663 602.409 301.808C602.409 135.241 466.867 0 301.205 0Z"
      />
    </svg>
  </div>
</template>

<style scoped>
.div-logo {
  @apply rounded-md w-fit p-1 bg-slate-hover hover:bg-limedark-eronor;
}
.svg-fb {
  @apply w-6 fill-white;
}
</style>
