<script setup lang="ts">
import LinkMyAccount from '@/components/dashboard/Link/LinkMyAccount.vue'
import LinkModifyAccount from '@/components/dashboard/Link/LinkModifyAccount.vue'
import LinkAddressAccount from '@/components/dashboard/Link/LinkAddressAccount.vue'
import LinkPasswordAccount from '@/components/dashboard/Link/LinkPasswordAccount.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import OrderSideBar from './OrderSideBar.vue'
import LinkHistoryAccount from './Link/LinkHistoryAccount.vue'
import LinkTransactionAccount from './Link/LinkTransactionAccount.vue'
import LinkCardPaiement from './Link/LinkCardPaiement.vue'
import LinkDeleteAccount from './Link/LinkDeleteAccount.vue'

const dashboardIsActive = ref(false)
const modifyIsActive = ref(false)
const passwordIsActive = ref(false)
const addressIsActive = ref(false)
const HistoryIsActive = ref(false)
const transactionIsActive = ref(false)
const recurringIsActive = ref(false)
const deleteAccountIsActive = ref(false)
const route = useRoute()

watch(
  () => route.name,
  (newRouteName) => {
    dashboardIsActive.value = newRouteName === 'dashboard'
    modifyIsActive.value = newRouteName === 'dashboard-edit'
    passwordIsActive.value = newRouteName === 'dashboard-password'
    addressIsActive.value = newRouteName === 'dashboard-address'
    HistoryIsActive.value = newRouteName === 'dashboard-order'
    transactionIsActive.value = newRouteName === 'dashboard-transaction'
    recurringIsActive.value = newRouteName === 'dashboard-recurring'
  },
  { immediate: true }
)
</script>

<template>
  <aside class="sidebar">
    <section class="section-sidebar">
      <LinkMyAccount :isActive="dashboardIsActive" />
      <section class="section-sidebar-dashboard">
        <div class="div-sidebard-account">
          <LinkModifyAccount :isActive="modifyIsActive" />
          <LinkPasswordAccount :isActive="passwordIsActive" />
          <LinkAddressAccount :isActive="addressIsActive" />
          <LinkDeleteAccount :is-active="deleteAccountIsActive" />
        </div>
        <OrderSideBar />
        <div class="div-sidebar-order">
          <LinkHistoryAccount :isActive="HistoryIsActive" />
          <!-- <LinkTransactionAccount :isActive="transactionIsActive" /> -->
          <!-- <LinkCardPaiement :isActive="recurringIsActive" /> -->
        </div>
      </section>
    </section>
  </aside>
</template>

<style scoped>
.sidebar {
  display: none;
}

@media (min-width: 700px) {
  .sidebar {
    @apply w-80 flex flex-col;
  }

  .section-sidebar {
    @apply rounded-md;
  }

  .section-sidebar-dashboard {
    @apply flex flex-col;
  }

  .div-sidebard-account {
    @apply bg-slate-eronor mb-1 border-b;
  }

  .div-sidebar-order {
    @apply bg-slate-eronor;
  }
}
</style>
