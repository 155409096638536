import type { SelectedProduct } from '@/event/cartBus'
import { languageIdBus } from '@/event/languageIdBus'
import { productBus } from '@/event/product/productBus'
import { selectionsBus } from '@/event/product/selectionsBus'
import { getOptionName } from './getOptionName'
import { convertPriceToEuro } from '../price/convertPrice'
import { getOptionValueNameById } from './getOptionValueNameById'
import { getOptionNameForBasket } from './getOptionNameForBasket'

export const getSelectedProducts = (): SelectedProduct[] => {
  const selectedProducts: SelectedProduct[] = []

  for (const [optionName, optionValues] of Object.entries(selectionsBus.value)) {
    let option
    if (productBus.value) {
      option = productBus.value.options.find(
        (opt: { language: any[] }) => getOptionNameForBasket(opt, languageIdBus.value) === optionName
      )
    }

    if (option) {
      if (Array.isArray(optionValues)) {
        optionValues.forEach((valueId: number) => {
          const value = option.values.find(
            (val: { option_value_id: number }) => val.option_value_id === valueId
          )
          const price = convertPriceToEuro(value.ov_price)
          const optionProductName = getOptionValueNameById(valueId, languageIdBus.value)
          const optionName = getOptionNameForBasket(option, languageIdBus.value)

          if (value && option.required) {
            if (!isNaN(Number(price))) {
              const paid = Number(price) > 0

              if (
                // option.po_sort_order === 1 &&
                option.type === 'radio' &&
                Array.isArray(value.related_option_id) &&
                value.related_option_id.length > 0
              ) {
                selectedProducts.push({
                  optionName,
                  productName: optionProductName,
                  valueId,
                  option_id: option.option_id,
                  price: value.ov_price,
                  paid: paid,
                  sort_order: option.po_sort_order,
                  type: option.type,
                  title: option.title,
                  reset: value.reset,
                  product_option_id: option.product_option_id
                })
              } else if (
                selectionsBus.value[optionName].length > option.free_option_count &&
                option.max_option_count > 0
              ) {
                const numberAddOvPrice = option.free_option_count

                const freeItems = selectionsBus.value[optionName].slice(0, numberAddOvPrice)
                const pricedItems = selectionsBus.value[optionName].slice(numberAddOvPrice)
                const valueIdAsNumber = Number(valueId)

                if (freeItems.includes(valueIdAsNumber)) {
                  selectedProducts.push({
                    optionName,
                    productName: optionProductName,
                    valueId,
                    option_id: option.option_id,
                    price: '0',
                    paid: false,
                    sort_order: option.po_sort_order,
                    type: option.type,
                    title: option.title,
                    reset: value.reset,
                    product_option_id: option.product_option_id
                  })
                }
                if (pricedItems.includes(valueIdAsNumber)) {
                  selectedProducts.push({
                    optionName,
                    productName: optionProductName,
                    valueId,
                    option_id: option.option_id,
                    price: value.ov_price,
                    paid: true,
                    sort_order: option.po_sort_order,
                    type: option.type,
                    title: option.title,
                    reset: value.reset,
                    product_option_id: option.product_option_id
                  })
                }
              } else if (
                selectionsBus.value[optionName].length > option.free_option_count &&
                option.max_option_count === 0
              ) {
                const numberAddOvPrice = option.free_option_count
                const freeItems = selectionsBus.value[optionName].slice(0, numberAddOvPrice)
                const pricedItems = selectionsBus.value[optionName].slice(numberAddOvPrice)

                if (freeItems.includes(valueId)) {
                  selectedProducts.push({
                    optionName,
                    productName: optionProductName,
                    valueId,
                    option_id: option.option_id,
                    price: '0',
                    paid: false,
                    sort_order: option.po_sort_order,
                    type: option.type,
                    title: option.title,
                    reset: value.reset,
                    product_option_id: option.product_option_id
                  })
                }

                if (pricedItems.includes(valueId)) {
                  selectedProducts.push({
                    optionName,
                    productName: optionProductName,
                    valueId,
                    option_id: option.option_id,
                    price: value.ov_price,
                    paid: true,
                    sort_order: option.po_sort_order,
                    type: option.type,
                    title: option.title,
                    reset: value.reset,
                    product_option_id: option.product_option_id
                  })
                }
              } else {
                selectedProducts.push({
                  optionName,
                  productName: optionProductName,
                  valueId,
                  option_id: option.option_id,
                  price: '0',
                  paid: false,
                  sort_order: option.po_sort_order,
                  type: option.type,
                  title: option.title,
                  reset: value.reset,
                  product_option_id: option.product_option_id
                })
              }
            }
          } else {
            if (!isNaN(Number(price))) {
              selectedProducts.push({
                optionName,
                productName: optionProductName,
                valueId,
                option_id: option.option_id,
                price: value.ov_price,
                paid: true,
                sort_order: option.po_sort_order,
                type: option.type,
                title: option.title,
                reset: value.reset,
                product_option_id: option.product_option_id
              })
            }
          }
        })
      } else {
        const valueId = optionValues.valueId
        const optionProductName = getOptionValueNameById(valueId, languageIdBus.value)
        const value = option.values.find(
          (val: { option_value_id: number }) => val.option_value_id === valueId
        )

        selectedProducts.push({
          optionName: getOptionNameForBasket(option, languageIdBus.value),
          productName: optionProductName,
          valueId,
          option_id: option.option_id,
          price: '0',
          paid: false,
          sort_order: option.po_sort_order,
          type: option.type,
          title: option.title,
          reset: value ? value.reset : 0,
          product_option_id: option.product_option_id
        })
      }
    }
  }
  return selectedProducts
}
