export function transformBasketProductOption(productOptions: any[]) {
  return productOptions.reduce((acc, option) => {
    const key = option.optionName || 'Sans nom'
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push({ productName: option.productName, price: option.price })
    return acc
  }, {})
}
