<script setup lang="ts">
import { urlAddressAdd } from '@/api/endpoint'
import { addressCustomer } from '@/event/account/dashboard/address/addressCustomer'
import {
    messageAddAddress,
    messageAddAddressEmpty
} from '@/event/account/dashboard/messageAddAddress'
import { showAlertAddAddress } from '@/event/account/dashboard/showAlertAddAddress'
import { addAddressBoolean } from '@/event/order/addAddressBoolean'
import { addressAdded } from '@/event/order/addressAdded'
import customer from '@/storage/customer'
import { configDeliveryZones } from '@/storage/setting/configDeliveryZones'
import stores from '@/storage/stores'
import type { DeliveryZone } from '@/types/stores/setting/DeliveryZoneInterface'
import { fetchAddress } from '@/utils/address/fetchAddress'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import { totalPrice } from '@/utils/price/totalPrice'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

const company = ref('')
const addressForm = ref('')
const supaddress = ref('')
const selectedRegion = ref('')
const selectedCity = ref('')

const optionRegion = ref<string[]>([])
const optionCity = ref<CityOption[]>([])

const selectedCountryId = ref<number | string>('')
const selectedZoneId = ref<number | string>('')
const selectedCityId = ref<number | string>('')

const dropdownOpen = ref<string | null>(null)
const showButton = ref(true)

const focused = ref()

const props = defineProps({ isClick: Boolean })

interface CityOption {
    city: string
    isSelectable: boolean
    minDeliveryAmount: number | null
}

function toggleDropdown(type: 'region' | 'city') {
    dropdownOpen.value = dropdownOpen.value === type ? null : type
}

function updateCityOptions() {
    optionCity.value = []
    selectedCity.value = ''

    if (selectedRegion.value) {
        optionCity.value = configDeliveryZones.value
            .filter((zone) => zone.zone === selectedRegion.value)
            .map((zone) => {
                const isSelectable = Number(totalPrice.value) >= zone.minimum_price
                return {
                    city: zone.city,
                    isSelectable,
                    minDeliveryAmount: isSelectable ? null : zone.minimum_price
                }
            })
            .sort((a, b) => a.city.localeCompare(b.city))
    }
}

function updateSelectedCityId() {
    const selectedCityData = configDeliveryZones.value.find(
        (zone) => zone.city === selectedCity.value && zone.zone === selectedRegion.value
    )

    if (
        selectedCityData &&
        selectedCityData.zone_id &&
        selectedCityData.country &&
        selectedCityData.city_id
    ) {
        selectedZoneId.value = selectedCityData.zone_id
        selectedCountryId.value = selectedCityData.country
        selectedCityId.value = selectedCityData.city_id
    }
}

function selectOptionRegion(option: string) {
    selectedRegion.value = option
    dropdownOpen.value = null
    updateCityOptions()
}

function selectOptionCity(option: any) {
    selectedCity.value = option.city
    dropdownOpen.value = null
    updateSelectedCityId()
}

function handleClickOutside(event: MouseEvent) {
    const target = event.target as HTMLElement
    if (!target.closest('.dropdown-select')) {
        dropdownOpen.value = null
    }
}

const loadOptions = () => {
    optionRegion.value = [
        ...new Set(configDeliveryZones.value.map((zone: DeliveryZone) => zone.zone))
    ].sort((a, b) => a.localeCompare(b))
    optionCity.value = []
}
loadOptions()

function toggleAddAddressForm() {
    showAlertAddAddress.value = false
}

const emit = defineEmits()

const addAddressOrder = async () => {
    emit('formSubmitted', { is_click: false })

    const data = {
        customer_id: customer.state.customerData.customer_id,
        customer_firstname: customer.state.customerData.firstname,
        customer_lastname: customer.state.customerData.lastname,
        address: addressForm.value,
        code_postal_id: selectedCityId.value,
        city: selectedCity.value,
        country_id: selectedCountryId.value,
        zone_id: selectedZoneId.value,

        company: company.value,
        address_2: supaddress.value,
        default: false
    }

    const isDataNotEmpty = data.address == '' && data.city == ''

    if (isDataNotEmpty) {
        const messageNotAdd = {
            message: 'Les données necessaire sont manquantes !',
            class: 'error',
            errors: [],
            address: 0
        }
        messageAddAddress.value = messageNotAdd
        return
    }

    try {
        messageAddAddress.value = await fetchPostAuthorization(urlAddressAdd, data)
        if (messageAddAddress.value.class == 'success') {
            addAddressBoolean.value = 2
            await fetchAddress()
            addressAdded.value = addressCustomer.value.find((ad) => {
                return ad.address_id == messageAddAddress.value.address
            })

            setTimeout(() => {
                messageAddAddress.value = messageAddAddressEmpty
                toggleAddAddressForm()
            }, 5000)
        } else {
            setTimeout(() => {
                messageAddAddress.value = messageAddAddressEmpty
                toggleAddAddressForm()
            }, 5000)
        }
    } catch (e) {}
    emit('formSubmitted', { is_click: true })
}
defineExpose({
    addAddressOrder
})

watch(
    () => stores.state.storeData.setting,
    (newValue) => {
        if (newValue) {
            loadOptions()
        }
    },
    {
        immediate: true
    }
)

onMounted(() => {
    document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside)
})
</script>

<template>
    <section class="section-form-address-order">
        <form class="form-order-address" @submit.prevent="addAddressOrder()">
            <div class="form-group-address">
                <input
                    type="text"
                    v-model="company"
                    id="company-address"
                    @focus="focused = 'company'"
                    @blur="focused = ''"
                    class="input-text"
                    :class="{ 'has-value': company !== '' || focused === 'company' }"
                />
                <label
                    class="label-text"
                    for="company-address"
                    :class="{ active: company !== '' || focused === 'company' }"
                >
                    Société
                </label>
            </div>

            <div class="form-group-address">
                <input
                    type="text"
                    v-model="addressForm"
                    id="address-address"
                    required
                    @focus="focused = 'address'"
                    @blur="focused = ''"
                    class="input-text"
                    :class="{ 'has-value': addressForm !== '' || focused === 'address' }"
                />
                <label
                    class="label-text"
                    for="address-address"
                    :class="{ active: addressForm !== '' || focused === 'address' }"
                >
                    <span>*</span>Adresse
                </label>
            </div>

            <div class="form-group-address">
                <textarea
                    v-model="supaddress"
                    id="address-supaddress"
                    @focus="focused = 'supaddress'"
                    @blur="focused = ''"
                    class="input-textarea"
                    :class="{ 'has-value': supaddress !== '' || focused === 'supaddress' }"
                    rows="4"
                ></textarea>
                <label
                    class="label-text"
                    for="address-supaddress"
                    :class="{ active: supaddress !== '' || focused === 'supaddress' }"
                    >Informations complémentaire
                </label>
            </div>

            <div class="form-group-select-address">
                <div
                    class="dropdown-select input-style"
                    @click="toggleDropdown('region')"
                    :class="{ open: dropdownOpen === 'region', 'has-value': selectedRegion }"
                    tabindex="0"
                >
                    <div class="div-arrow">
                        <div class="arrow" :class="{ 'rotate-up': dropdownOpen === 'region' }">
                            &#9660;
                        </div>
                    </div>
                    <p class="pselect" :class="{ pselect: dropdownOpen === 'region' }">
                        {{ selectedRegion || '' }}
                    </p>
                    <ul v-if="dropdownOpen === 'region'" class="dropdown-options">
                        <li class="border-t" @click="selectOptionRegion('')">--</li>
                        <li
                            class="pselectborder"
                            v-for="(option, index) in optionRegion"
                            :key="index"
                            @click="selectOptionRegion(option)"
                        >
                            {{ option }}
                        </li>
                    </ul>
                </div>
                <label
                    class="label-select"
                    @click.stop="toggleDropdown('region')"
                    :class="{ active: selectedRegion || dropdownOpen === 'region' }"
                >
                    <span>*</span>Région
                </label>
            </div>

            <div class="form-group-select-address">
                <div
                    class="dropdown-select input-style"
                    @click="toggleDropdown('city')"
                    :class="{ open: dropdownOpen === 'city', 'has-value': selectedCity }"
                >
                    <div class="div-arrow">
                        <div class="arrow" :class="{ 'rotate-up': dropdownOpen === 'city' }">
                            &#9660;
                        </div>
                    </div>
                    <p class="pselect" :class="{ pselect: dropdownOpen === 'city' }">
                        {{ selectedCity }}
                    </p>
                    <ul v-if="dropdownOpen === 'city'" class="dropdown-options">
                        <li class="border-t" @click="selectOptionCity('')">--</li>
                        <li
                            class="pselectborder hover:bg-slate-300"
                            v-for="(option, index) in optionCity"
                            :key="index"
                            @click.stop="option.isSelectable && selectOptionCity(option)"
                        >
                            {{ option.city }}
                            <p v-if="option.minDeliveryAmount" class="text-red-eronor">
                                le prix de la commande minimum est {{ option.minDeliveryAmount }}€
                            </p>
                        </li>
                    </ul>
                </div>
                <label
                    class="label-select"
                    @click.stop="toggleDropdown('city')"
                    :class="{ active: selectedCity || dropdownOpen === 'city' }"
                >
                    <span>*</span>Ville
                </label>
            </div>
        </form>
    </section>
</template>

<style scoped>
.section-form-address-order {
    @apply w-full flex justify-center;
}

.form-order-address {
    @apply flex flex-col items-center gap-7 pt-7 p-3 w-full;
}

.form-group-address {
    @apply relative flex flex-col items-center w-full;
}

.form-group-select-address {
    @apply relative flex flex-col items-center w-full;
}

.label-text {
    @apply absolute text-slate-eronor;
    transition: all 1s ease;
}

.label-select {
    @apply absolute text-slate-eronor transition-all ease-in-out duration-300;
    transition: all 1s ease;
}

.button-add-address-form-order {
    @apply bg-lime-eronor p-2 rounded-lg hover:bg-limedark-eronor;
}

.label-select.active {
    @apply -top-6 text-white-eronor;
}

.input-text.has-value + .label-text,
.input-text:focus + .label-text {
    @apply -top-6 text-white-eronor;
}

.input-text.has-value + label,
.input-text:focus + label {
    @apply -top-6 text-white-eronor;
}

.input-textarea.has-value + .label-text,
.input-textarea:focus + .label-text {
    @apply -top-6 text-white-eronor;
}

.input-text {
    @apply text-slate-eronor text-center min-w-64 rounded-2xl border-2 outline-none focus:border-red-eronor;
}

.input-style {
    @apply text-slate-eronor text-center min-w-64 rounded-2xl border-2 outline-none transition-all ease-in-out h-7;
}

.input-textarea {
    @apply text-slate-eronor text-center min-w-64 rounded-2xl border-2 outline-none focus:border-red-eronor;
    padding: 8px;
    min-height: 4rem;
    resize: vertical;
}

label.active {
    transition: all 1s ease;
}

.dropdown-select {
    @apply relative flex justify-center cursor-pointer bg-white rounded-2xl;
}

.dropdown-options {
    @apply absolute bg-white-eronor max-h-40 z-30 left-0 right-0 pt-4 mt-[6px] p-2;

    top: 50%;
    overflow-y: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.pselect {
    @apply absolute z-20;
}

.pselectborder {
    @apply border-t;
}

span {
    @apply text-red-eronor;
}

.rotate-up {
    @apply z-30;
    transform: rotate(180deg);
}

.arrow {
    @apply absolute bg-white-eronor z-30 w-6 rounded-lg;
}

.div-arrow {
    @apply pl-48;
}

@media screen and (min-width: 700px) {
    .form-order-address {
        @apply gap-2 items-center pt-3 justify-center w-full;
    }

    .form-group-address {
        @apply flex-row-reverse gap-3 w-4/5;
    }

    .form-group-select-address {
        @apply flex-row-reverse gap-3 w-4/5;
    }

    .label-text {
        @apply relative text-white-eronor;
    }

    .label-select {
        @apply relative text-white-eronor;
    }

    .label-select.active {
        @apply -top-0 text-white-eronor;
    }

    .input-text.has-value + .label-text,
    .input-text:focus + .label-text {
        @apply -top-0 text-white-eronor;
    }

    .input-text.has-value + label,
    .input-text:focus + label {
        @apply -top-0 text-white-eronor;
    }

    .input-textarea.has-value + .label-text,
    .input-textarea:focus + .label-text {
        @apply -top-0 text-white-eronor;
    }

    .input-text {
        @apply min-w-96;
    }

    .input-style {
        @apply min-w-96 h-7;
    }

    .input-textarea {
        @apply min-w-96 p-2 min-h-10;
        resize: vertical;
    }

    .div-arrow {
        @apply pl-80;
    }
}

@media screen and (min-width: 1200px) {
    .form-group-address {
        @apply w-3/5;
    }

    .form-group-select-address {
        @apply w-3/5;
    }

    .input-text {
        @apply min-w-[30rem];
    }

    .input-style {
        @apply min-w-[30rem];
    }

    .input-textarea {
        @apply min-w-[30rem];
    }
}
</style>
