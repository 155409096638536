import { productBus } from '@/event/product/productBus'
import {
  addBasketAlert,
  alertElement,
  alertMessage,
  alertWarning,
  alertWarningMessage
} from './alert'
import { getSelectedProducts } from './getSelectedProducts'
import { getTotalPrice } from './getTotalPrice'
import type { Option } from '@/storage/products'
import { relatedGroupBus } from '@/event/product/relatedGroupeBus'
import { getOptionName } from './getOptionName'
import { languageIdBus } from '@/event/languageIdBus'
import { selectionsBus } from '@/event/product/selectionsBus'
import { cartBus, indexUpdateElement } from '@/event/cartBus'
import { categoryIdBus } from '@/event/category/categoryIdBus'
import { getProductName } from './getProductName'
import { quantityBus } from '@/event/product/quantityBus'
import { priceBasketBus } from '@/event/product/priceBasketBus'
import { extraPricesBus } from '@/event/product/extraPricesBus'
import { addElementBasketBus } from '@/event/product/addElementBasketBus'
import { showBasketLogoBus } from '@/event/product/showBasketBus'
import { extractRelatedOptions } from './extractRelatedOptions'
import { getProductTax } from './getProductTax'
import { clearSelection } from './clearSelection'
import { getOptionNameForBasket } from './getOptionNameForBasket'
import { categoryBus } from '@/event/category/categoryBus'

function areProductsEqual(product1: any, product2: any) {
  return (
    product1.category_id === product2.category_id &&
    product1.product_id === product2.product_id &&
    JSON.stringify(product1.product_option) === JSON.stringify(product2.product_option) &&
    product1.price === product2.price &&
    product1.rate_tax === product2.rate_tax &&
    product1.lbl_printer_id === product2.lbl_printer_id &&
    product1.printer_id === product2.printer_id
  )
}

export const addToCart = () => {
  const selectedProducts = getSelectedProducts()
  const totalPrice = getTotalPrice()
  let numberSelect
  alertWarningMessage.value = []

  if (productBus.value) {
    productBus.value.options.forEach((option: Option) => {
      const findRelatedOptionId = relatedGroupBus.value.find((e) => e === option.option_id)
      const optionName = getOptionNameForBasket(option, languageIdBus.value)

      if (!selectionsBus.value[optionName]) {
        selectionsBus.value[optionName] = []
      }

      const selectedValues = selectionsBus.value[optionName]
      const resetIndex = selectedValues.findIndex((valId: number) => {
        const val = option.values.find((v: any) => v.option_value_id === valId)
        return val && val.reset === 1
      })

      if (findRelatedOptionId) {
        if (
          option.required &&
          option.free_option_count > 0 &&
          selectedValues.length < option.free_option_count
        ) {
          if (resetIndex !== -1) {
            numberSelect = 0
          } else {
            numberSelect = selectedValues.length
          }
          const numberOptionNeed = option.free_option_count
          const numberRemaining = numberOptionNeed - numberSelect

          alertWarningMessage.value.push(`${optionName}: ${numberRemaining} choix possible`)
        }
      } else {
        if (
          option.required &&
          option.free_option_count > 0 &&
          selectedValues.length < option.free_option_count
        ) {
          if (selectedValues.length === 0) {
            alertElement.value.push(optionName)
          } else {
            if (resetIndex !== -1) {
              numberSelect = 0
            } else {
              numberSelect = selectedValues.length
            }
            const numberOptionNeed = option.free_option_count
            const numberRemaining = numberOptionNeed - numberSelect

            alertWarningMessage.value.push(`${optionName}: ${numberRemaining} choix possible`)
          }
        } else if (option.required && option.free_option_count == 0 && selectedValues.length < 1) {
          alertElement.value.push(optionName)
        }
      }
    })

    if (alertElement.value.length > 0) {
      alertMessage.value = `Veuillez sélectionner les options requises :`
      return
    } else if (alertWarningMessage.value.length > 0) {
      alertWarning.value = 'Vous pouvez encore faire certain(s) choix :'
      return
    } else {
      const categorySortOrder = categoryBus.value?.find((category) => category.category_id === categoryIdBus.value)?.sort_order as number

      if (indexUpdateElement.value !== null) {
        cartBus.value[indexUpdateElement.value] = {
          c_sort_order: categorySortOrder,
          category_id: categoryIdBus.value,
          product_image: productBus.value.image,
          product_id: productBus.value.product_id,
          product: getProductName(productBus.value),
          product_option: selectedProducts,
          price: Number(productBus.value.price),
          total_price: totalPrice,
          price_tax: getProductTax(totalPrice, productBus.value.rate),
          rate_tax: productBus.value.rate,
          quantity: quantityBus.value,
          lbl_printer_id: productBus.value.lbl_printer_id,
          printer_port: 9100,
          printer_id: productBus.value.printer_id
        }
        indexUpdateElement.value = null
      } else {
        const newProduct = {
          c_sort_order: categorySortOrder,
          category_id: categoryIdBus.value,
          product_image: productBus.value.image,
          product_id: productBus.value.product_id,
          product: getProductName(productBus.value),
          product_option: selectedProducts,
          price: Number(productBus.value.price),
          total_price: totalPrice,
          price_tax: getProductTax(totalPrice, productBus.value.rate),
          rate_tax: productBus.value.rate,
          quantity: quantityBus.value,
          lbl_printer_id: productBus.value.lbl_printer_id,
          printer_port: 9100,
          printer_id: productBus.value.printer_id
        }

        const existingProduct = cartBus.value.find((product) =>
          areProductsEqual(product, newProduct)
        )

        if (existingProduct) {
          existingProduct.quantity += newProduct.quantity
          existingProduct.total_price += newProduct.total_price
          existingProduct.price_tax += getProductTax(newProduct.total_price, productBus.value.rate)
        } else {
          cartBus.value.push(newProduct)
        }
      }

      priceBasketBus.value = totalPrice
      extraPricesBus.value = {}
      addBasketAlert.value = 'Ajouté au panier'
      addElementBasketBus.value = selectedProducts.map((product) => {
        return {
          optionName: product.optionName,
          productName: product.productName,
          sort_order: product.sort_order
        }
      })
      quantityBus.value = 1
      showBasketLogoBus.value = true
      clearSelection()
      alertWarning.value = null

      selectionsBus.value = []

      setTimeout(() => {
        showBasketLogoBus.value = false
      }, 2000)

      setTimeout(() => {
        addBasketAlert.value = ''
      }, 4000)
    }
  }
}
