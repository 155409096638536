import { reactive } from 'vue'

interface Address {
  address_id: number
  customer_id: number
  firstname: string
  lastname: string
  company?: string | null
  address_1: string
  address_2?: string | null
  city: string
  postcode: string
  country_id: number
  zone_id: number
  code_postal_id: number
  custom_field?: string | null
  default: boolean | null
}

const state = reactive({
  addressData: [] as Address[]
})

function setAddressData(data: Address[]) {
  state.addressData = data
}

function getAddressData() {
  return state.addressData
}

export type { Address }

export default {
  state,
  setAddressData,
  getAddressData
}
