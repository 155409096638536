export const updateMostRecentOrderClass = (
  orderStatusId: number,
  mostRecentOrderClass: { value: string }
) => {
  if (orderStatusId === 1 || orderStatusId === 12 || orderStatusId === 4 || orderStatusId === 9) {
    mostRecentOrderClass.value = 'wait-class'
  } else if (orderStatusId === 2) {
    mostRecentOrderClass.value = 'preparation-class'
  } else if (orderStatusId === 5) {
    mostRecentOrderClass.value = 'approve-class'
  } else if (orderStatusId === 3) {
    mostRecentOrderClass.value = 'ready-class'
  } else if (orderStatusId === 6) {
    mostRecentOrderClass.value = 'delivery-class'
  } else {
    mostRecentOrderClass.value = ''
  }
}

export const updateMostRecentOrderText = (
  orderStatusId: number,
  mostRecentOrderText: { value: string }
) => {
  if (orderStatusId === 1 || orderStatusId === 12) {
    mostRecentOrderText.value = 'En attente'
  } else if (orderStatusId === 4) {
    mostRecentOrderText.value = 'Annulé'
  } else if (orderStatusId === 2) {
    mostRecentOrderText.value = 'Préparation'
  } else if (orderStatusId === 5) {
    mostRecentOrderText.value = 'Approuvé'
  } else if (orderStatusId === 3) {
    mostRecentOrderText.value = 'Prêt'
  } else if (orderStatusId === 6) {
    mostRecentOrderText.value = 'Remise'
  } else {
    mostRecentOrderText.value = ''
  }
}
