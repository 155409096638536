<script setup lang="ts">
import ButtonContinueOrder from '@/components/button/ButtonContinueOrder.vue'
import { watch } from 'vue'
import { cartBus } from '@/event/cartBus'
import { sectionOrderBus } from '@/event/sectionOrderBus'
import ButtonLinkBasketSectionOrder from '../button/basket/ButtonLinkBasketSectionOrder.vue'

watch(
  () => sectionOrderBus.value,
  () => {
    const sectionOrder = document.getElementById('section-order')
    if (sectionOrder) {
      if (sectionOrderBus.value == true) {
        sectionOrder.classList.add('isFlex')
      } else {
        sectionOrder.classList.remove('isFlex')
      }
    }
  },
  { deep: true }
)
</script>

<template>
  <section
    id="section-order"
    v-if="cartBus.length > 0"
    class="section-order"
    :class="{ isFlex: sectionOrderBus }"
  >
    <section class="section-button-order">
      <ButtonContinueOrder />
      <ButtonLinkBasketSectionOrder />
    </section>
  </section>
</template>

<style scoped>
.section-order {
  @apply hidden relative w-full px-1 pt-1;
}

.section-order.isFlex {
  @apply flex;
}

.section-button-order {
  @apply flex justify-between w-full px-1 gap-1;
}

@media (max-width: 350px) {
  .section-button-order > * {
    @apply flex-1; /* Permet à chaque enfant d'avoir la même taille seulement jusqu'à 400px */
  }
}

@media screen and (min-width: 800px) {
  .section-order {
    @apply hidden w-full container px-2;
  }
}

@media screen and (min-width: 1030px) {
  .section-order {
    @apply w-[60rem];
  }
}

@media screen and (min-width: 1500px) {
  .section-order {
    @apply w-[75rem];
  }
}
</style>
