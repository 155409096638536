import { ref } from 'vue'
import products, { type Product } from '@/storage/products'

export function useProducts() {
  const product = ref<Product[]>(products.state.productData)

  const getProduct = (productId: number) => {
    return product.value.find((prod) => prod.product_id === productId)
  }

  return {
    product,
    getProduct
  }
}
