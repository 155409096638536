import { computed } from 'vue'
import { configAddress } from './setting/configAddress'

export const mapUrl = computed(() => {
  const encodedAddress = encodeURIComponent(configAddress.value)
  return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
})

export const openMap = () => {
  const encodedAddress = encodeURIComponent(configAddress.value.value)
  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)

  if (isMobile) {
    window.location.href = `geo:0,0?q=${encodedAddress}`
  } else {
    window.open(mapUrl.value, '_blank')
  }
}
