import { cartBus, indexUpdateElement } from '@/event/cartBus'
import { relatedGroupBus } from '@/event/product/relatedGroupeBus'

export const extractRelatedOptions = (productOptions: any[]) => {
  if (productOptions) {
    const tempSet = new Set(relatedGroupBus.value)

    productOptions.forEach((option) => {
      option.values.forEach((value: { related_option_id: any }) => {
        if (value.related_option_id && Array.isArray(value.related_option_id)) {
          value.related_option_id.forEach((id) => {
            tempSet.add(id)
          })
        }
      })
    })

    relatedGroupBus.value = Array.from(tempSet)
  }

  if (indexUpdateElement.value !== null) {
    if (cartBus.value[indexUpdateElement.value]) {
      const cartElement = cartBus.value[indexUpdateElement.value].product_option

      if (cartElement) {
        cartElement.forEach((element: { option_id: number }) => {
          relatedGroupBus.value = relatedGroupBus.value.filter((id) => id !== element.option_id)
        })
      }
    }
  }
}
