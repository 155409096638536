import stores from '@/storage/stores'
import { watch } from 'vue'
import { catalogueBase, logo, settingNavbar } from '../../main/navbar/statesNavbar'
import { type RouteLocationNormalizedLoaded } from 'vue-router'
import { categoryIdBus } from '@/event/category/categoryIdBus'
import { fetchAddress } from '@/utils/address/fetchAddress'
import { updateAddressData } from '@/event/account/dashboard/updateAddressData'
import { showAlertLogin } from '@/event/account/alert/showAlertLogin'
import router from '@/router'
import { isAuthenticated } from '@/event/account/auth/authentification'
import { messageLogin, typeMessageLogin } from '@/event/account/alert/messageLogin'

export function watchersNavbar(route: RouteLocationNormalizedLoaded) {
  const handleLoginRedirect = (routePath: string) => {
    if (['dashboard'].includes(routePath) && !isAuthenticated.value) {
      router.push('/')
    }
  }

  watch(
    () => route.name,
    (newRoute, oldRoute) => {
      if (oldRoute === 'login' && newRoute === 'register') {
        messageLogin.value = typeMessageLogin
        showAlertLogin.value = false
      }
    }
  )

  watch(
    () => stores.state.storeData,
    () => {
      catalogueBase.value = stores.state.storeData.category
    },
    { deep: true }
  )

  watch(
    () => route.params.category_id,
    (newCategoryId) => {
      categoryIdBus.value = newCategoryId ? Number(newCategoryId) : 0
    },
    { immediate: true }
  )

  watch(
    () => stores.state.storeData,
    (newValue) => {
      if (newValue && newValue.setting) {
        settingNavbar.value = stores.getStoresData().setting
        const logoItem = settingNavbar.value.find(
          (item: { key: string }) => item.key === 'config_logo'
        ).value

        logo.value = logoItem ?? null
      }
    },
    { immediate: true }
  )

  watch(
    () => updateAddressData.value,
    async () => {
      if (updateAddressData.value === true) {
        await fetchAddress()
        updateAddressData.value = false
      }
    },
    { immediate: true }
  )
}
