import type { DateInterface } from '@/types/date'
import { reactive } from 'vue'

export interface OrderInterface {
  date_added: DateInterface
  date_modified: DateInterface | null
  delivery_time: string
  invoice_no: number
  order_date: DateInterface
  order_id: number
  order_status_id: number
  payment_method: string
  shipping_method: string
  total: string
  total_CC: string
  total_NKT: string
  total_TIC: string
  order_products: OrderProduct[]
}

export interface OrderProduct {
  name: string
  order_id: number
  order_product_id: number
  price: string
  product_id: number
  quantity: number
  total: string
  order_options: OrderOption[]
}

export interface OrderOption {
  order_id: number
  order_product_id: number
  name: string
  value: string
  po_sort_order: number
}

export interface TransformedOrder {
  date_added: string
  date_modified: string
  order_date: string
  raw_date_added: Date | null
  total: number
  payment_status: string

  invoice_no: number
  order_id: number
  order_status_id: number
  payment_method: string
  shipping_method: string
  total_CC: string
  total_NKT: string
  total_TIC: string
  order_products: OrderProduct[]
}

const state = reactive({
  orderData: [] as OrderInterface[]
})

function setOrderData(data: OrderInterface[]) {
  state.orderData.splice(0, state.orderData.length, ...data)
}

function getOrderData() {
  return state.orderData
}

export default {
  state,
  setOrderData,
  getOrderData
}
