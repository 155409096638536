import { isAuthenticated } from '@/event/account/auth/authentification'
import { showValidateOrder } from '@/event/order/showValidateOrder'
import router from '@/router'

export const toggleValidateOrder = () => {
  if (isAuthenticated.value) {
    router.push({ path: '/basket/order' })
  } else {
    showValidateOrder.value = !showValidateOrder.value
  }
}
