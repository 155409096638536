import { computed } from 'vue'
import stores from '../stores'

export const configTaxNumber = computed(() => {
  const dataTaxNumber = stores.state.storeData.setting?.find(
    (s: { key: string }) => s.key == 'config_tax_number'
  )

  return dataTaxNumber?.value || '-----------'
})
