export function decodeJWT(token: any) {
  const parts = token.split('.')

  // if (parts.length !== 3) {
  //   throw new Error('Invalid JWT token')
  //   return
  // }

  const payload = parts[1]
  const base64 = payload.replace(/-/g, '+').replace(/_/g, '/')
  const padding = '='.repeat((4 - (base64.length % 4)) % 4)
  const base64WithPadding = base64 + padding

  const jsonPayload = decodeURIComponent(
    atob(base64WithPadding)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
