<script setup lang="ts">
import { isAuthenticated } from '@/event/account/auth/authentification'
import { messageDeleteAccount } from '@/event/account/alert/messageDeleteAccount'
import { showAlertDeleteAccount } from '@/event/account/alert/toggle/toggleAlertDeleteAccount'
import { messageAlert } from '@/event/alert/messageAlert'
import customer from '@/storage/customer'
import stores from '@/storage/stores'
import { formatStoreName } from '@/utils/store/formatStoreName'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { fetchPostAuthorization } from '@/utils/fetch/fetchPostAuthorization'
import { urlCustomerDelete } from '@/api/endpoint'
import { showSelectDeleteCustomer } from '@/event/account/delete/showSelectDeleteCustomer'

async function selectDeleteAccount() {
  showSelectDeleteCustomer.value = true
}
</script>

<template>
  <button class="button-delete-account" @click="selectDeleteAccount">
    <svg class="svg-delete" viewBox="-25 0 600 690" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M431.625 622.875H94.125C90.8098 622.875 87.6304 621.558 85.2862 619.214C82.942 616.87 81.625 613.69 81.625 610.375L56.625 160.375H468.75L443.75 610.375C443.751 613.626 442.486 616.75 440.222 619.084C437.959 621.418 434.875 622.777 431.625 622.875Z"
        stroke-width="28"
      />
      <path
        d="M500.5 160.25H25.5C22 160.25 19.25 158.25 19.25 155.875L30.875 101.5C31.3777 100.288 32.2563 99.2697 33.3815 98.5945C34.5067 97.9194 35.819 97.6235 37.125 97.7504H488.875C490.181 97.6235 491.493 97.9194 492.619 98.5945C493.744 99.2697 494.622 100.288 495.125 101.5L506.75 155.875C506.75 158.25 504 160.25 500.5 160.25Z"
        stroke-width="28"
      />
      <path d="M163 230V542.5" stroke-width="28" />
      <path d="M263 230V542.5" stroke-width="28" />
      <path d="M363 230V542.5" stroke-width="28" />
      <path
        d="M184.625 97.75V31.625C184.625 28.3098 185.942 25.1304 188.286 22.7862C190.63 20.442 193.81 19.125 197.125 19.125H328.875C332.19 19.125 335.37 20.442 337.714 22.7862C340.058 25.1304 341.375 28.3098 341.375 31.625V97.75"
        stroke-width="28"
      />
    </svg>

    <div>
      <p class="text-delete">Supprimer mon compte</p>
    </div>
  </button>
</template>

<style scoped>
.button-delete-account {
  @apply w-full flex items-center p-2 h-16 border gap-3;
}

.svg-delete {
  @apply pr-2 w-10 h-10 stroke-lime-eronor fill-transparent border-r;
}

.text-delete {
  @apply font-semibold;
}

.button-delete-account:hover,
.button-delete-account:hover .svg-delete,
.button-delete-account:hover .text-delete {
  @apply border-lime-eronor text-lime-eronor;
}
@media (min-width: 900px) {
  .button-delete-account {
    @apply h-20;
  }
  .svg-delete {
    @apply w-12;
  }
}
</style>
