import { categoryBus } from '@/event/category/categoryBus'
import { categoryIdBus } from '@/event/category/categoryIdBus'
import { categoryNameBus } from '@/event/category/categoryNameBus'
import { showCategoryBus } from '@/event/category/showCategoryBus'
import stores from '@/storage/stores'
import { getCategoryName } from '../category/category'

export const updateCategory = () => {
  categoryBus.value = stores.state.storeData.category
  if (categoryBus.value) {
    showCategoryBus.value =
      categoryBus.value.find((item) => item.category_id === categoryIdBus.value) || null
  }
  if (showCategoryBus.value) {
    categoryNameBus.value = getCategoryName(showCategoryBus.value)
  }
}
