
import { ref } from 'vue'
import { fetchCsrf } from '../csrf/fetchCsrf'
import { URL_BACK } from '@/config/constants'

const csrfToken = ref<string | null>(null)

export async function fetchPost(urlEnd: string, data: any) {
  try {
    await fetchCsrf(csrfToken)

    const url = `https://${URL_BACK}/api/${urlEnd}`

    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken.value ?? ''
      },
      body: JSON.stringify(data)
    })
    const response = await res.json()
    return response
  } catch (e) {
    return
  }
}
