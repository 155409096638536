export const getOptionName = (option: { language: any[] }, languageId: number) => {
  const lang = option.language.find((lang) => lang.language_id === languageId)
  // Si un langage est trouvé, on vérifie s'il a un label
  if (lang) {
    // Si un label existe, on priorise le label
    if (lang.label !== null && lang.label !== undefined && lang.label.length !== 0) {
      return lang.label
    }
    // Sinon, on renvoie le nom
    return lang.name
  }

  // Si aucun langage n'est trouvé, on renvoie "Unknown"
  return 'Unknown'
}


