import { cartBus } from '@/event/cartBus'
import { shippingCost } from '@/event/order/data/shippingCost'
import { computed } from 'vue'

export const totalPrice = computed(() => {
  let total = cartBus.value.reduce((total, item) => {
    return total + item.total_price
  }, 0)
  // total += Number(shippingCost.value)
  return Number(total).toFixed(2)
})
