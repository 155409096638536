import { computed } from 'vue'
import stores from '../stores'

export const configAddress = computed(() => {
  const dataConfigAddress = stores.state.storeData.setting?.find(
    (setting: { key: string }) => setting.key === 'config_address'
  )

  return dataConfigAddress?.value || null
})
