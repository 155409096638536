export function useDaysFormatter() {
  const daysWeek: { [key: string]: string } = {
    1: 'Lun',
    2: 'Mar',
    3: 'Mer',
    4: 'Jeu',
    5: 'Ven',
    6: 'Sam',
    7: 'Dim'
  }

  const formatDays = (days: string[]): string => {
    const hasConsecutiveDays = (days: string[]): boolean => {
      const sortedDays = days.map((day) => parseInt(day)).sort((a, b) => a - b)
      return sortedDays.every((day, index) => {
        if (index === 0) return true
        return day === sortedDays[index - 1] + 1
      })
    }

    return hasConsecutiveDays(days)
      ? `${daysWeek[days[0]]} - ${daysWeek[days[days.length - 1]]}`
      : days.map((jour) => daysWeek[jour]).join(', ')
  }

  return {
    formatDays
  }
}
