const { updateStore, storeData, testDay, testHour, testMinute } = useStoreInfo()

import { storeHeader } from '@/event/main/header/storeHeader'
import stores from '@/storage/stores'
import { classTitle, title, useStoreInfo } from '@/utils/useStoreInfo'
import { watch } from 'vue'

export function watchersHeader() {
  watch(
    () => stores.state.storeData,
    () => {
      const data: any = stores.getStoresData()
      if (data) {
        updateStore(data)
        if (title.value && title.value !== storeHeader.title) {
          storeHeader.title = title.value
        }

        if (classTitle.value && classTitle.value !== storeHeader.classTitle) {
          storeHeader.classTitle = classTitle.value
        }
      }
    },
    { deep: true, immediate: true }
  )

  watch(
    [testDay, testHour, testMinute],
    () => {
      const data: any = stores.getStoresData()
      if (data) {
        updateStore(data)
        storeHeader.title = title.value
        storeHeader.classTitle = classTitle.value
      }
    },
    { immediate: false }
  )
}
