import { watch } from 'vue'
import { ref } from 'vue'

export const isAuthenticated = ref(false)
// const routerRedirect = useRouter()

watch(
  () => isAuthenticated.value,
  (newValue: boolean) => {
    // if (newValue === false) routerRedirect.push('/')
  }
)
