import { url } from "@/event/url/url"

export let URL_BACK: string
export let URL_IMAGE: string

const hostname = url.host
const parts = hostname.split('.')
const numPart = parts.length

export const arrayUrlTest = ['localhost', 'dev.eronor.eu', 'menuboard.fr']

if (arrayUrlTest.includes(url.host)) {
    URL_BACK = 'back.eronor.eu'
    URL_IMAGE = 'https://back.eronor.eu/'
} else if (parts[numPart - 1] === 'fr') {
    URL_BACK = 'back.abikebab.fr'
    URL_IMAGE = 'https://back.abikebab.fr/'
} else {

}

