<script setup lang="ts">
import { showSelectDeleteCustomer } from '@/event/account/delete/showSelectDeleteCustomer'

const props = defineProps({ isActive: Boolean })

async function selectDeleteAccount() {
  showSelectDeleteCustomer.value = true
}
</script>

<template>
  <button class="link-delete" :class="{ active: props.isActive }" @click="selectDeleteAccount">
    <p>Supprimer mon compte</p>
  </button>
</template>

<style scoped>
.link-delete {
  @apply flex gap-2 p-2 w-full;
}

.link-delete p {
  @apply text-sm ml-7;
}

.active {
  @apply bg-slate-hover text-lime-eronor;
}

.link-delete:hover,
.link-delete:hover p {
  @apply bg-slate-hover text-lime-eronor;
}
</style>
