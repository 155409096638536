import stores from '@/storage/stores'
import { ref, watch } from 'vue'

export const theme = ref(localStorage.getItem('theme') || 'default')

const VALID_THEMES = ['default', 'smoke', 'smoke-white']

// watch(theme, (newTheme) => {
//   if (!VALID_THEMES.includes(newTheme)) {
//     theme.value = 'default'
//     return
//   }

//   const racine = document.documentElement.classList
//   racine.remove(theme.value)
//   racine.add(newTheme)

//   localStorage.setItem('theme', theme.value)
// })
export const applyTheme = (newTheme: string) => {
  if (!VALID_THEMES.includes(newTheme)) {
    newTheme = 'default' // Appliquer un thème par défaut si le thème est invalide
  }

  // Appliquer le thème dans le document HTML
  document.documentElement.classList.remove(...VALID_THEMES) // Enlever les anciennes classes
  document.documentElement.classList.add(newTheme) // Ajouter la nouvelle classe
  localStorage.setItem('theme', newTheme) // Mettre à jour le localStorage
}
