import { orderArianeBus } from '@/event/ariane/orderArianeBus'

export function selectLinkArianeOrder(routePath: string) {
  const data = {
    active: false,
    link: '',
    name: ''
  }

  if (routePath.includes('basket/order')) {
    data.active = true
    data.link = '/basket/order'
    data.name = 'Validation de commande'
  }

  orderArianeBus.value = data
}
