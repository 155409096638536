<script setup lang="ts">
import { URL_IMAGE } from '@/config/constants'
import { isNavbarVisible } from '@/event/isNavbarVisible'
import { useRoute, useRouter } from 'vue-router'
import { useCarousel } from '@/utils/main/home/useCarousel'
import { cartBus } from '@/event/cartBus'
import { sectionOrderBus } from '@/event/sectionOrderBus'
import { theme } from '@/event/theme/theme'
import { defineAsyncComponent } from 'vue'
import { decodeJWT } from '@/utils/jwt/decodeJwt'
import { configAreas } from '@/storage/setting/configAreas'
import { alertTableQrCodeShow } from '@/event/alert/alertTableQrCodeShow'
import { nameTableRef } from '@/event/alert/nameTableRef'
import stores from '@/storage/stores'
import { formatStoreName } from '@/utils/store/formatStoreName'

const CatalogueCategory = defineAsyncComponent(
    () => import('@/components/main/CatalogCategory.vue')
)
const CatalogCategorySmoke = defineAsyncComponent(
    () => import('@/firstTheme/components/home/CatalogCategorySmoke.vue')
)

const route = useRoute()
const table = route.params.table

if (table) {
    const decodeTable = decodeJWT(route.params.table)
    const [nameTable, numberTable] = decodeTable.table.split('-')
    const expirationTime = 30 * 60 * 1000
    const expirationTimestamp = new Date().getTime() + expirationTime
    if (
        Array.isArray(configAreas.value.areas) &&
        configAreas.value.length !== 0 &&
        configAreas.value.areas.length > 0
    ) {
        const area = configAreas.value.areas.find((area: any) => area.areaName === nameTable)
        const store = stores.state.storeData.name
        const key = store ? `${formatStoreName(store)}_table` : 'table'

        if (area && numberTable <= area.maxTableNumber) {
            alertTableQrCodeShow.value = true
            nameTableRef.value = decodeTable.table

            localStorage.setItem(
                key,
                JSON.stringify({ value: decodeTable.table, expiration: expirationTimestamp })
            )

            setTimeout(() => {
                alertTableQrCodeShow.value = false
            }, 3000)

            setTimeout(() => {
                localStorage.removeItem(key)
            }, expirationTime)
        }
    }
}

const {
    currentIndex,
    bannerData,
    carouselInner,
    nextSlide,
    previousSlide,
    startCarousel,
    touchStart,
    touchMove,
    touchEnd,
    mouseDown,
    mouseMove,
    mouseUp,
    updateCarouselPosition
} = useCarousel()

const toggleDisplay = () => {
    isNavbarVisible.value = true
    if (cartBus.value.length > 0) {
        sectionOrderBus.value = true
    }
}
</script>

<template>
    <div class="w-full overflow-hidden">
        <CatalogCategorySmoke v-if="theme === 'smoke' || theme === 'smoke-white'" />

        <div class="w-full" v-if="theme === 'default'">
            <section :class="{ section: isNavbarVisible, hidden: isNavbarVisible }">
                <div class="carousel" v-if="bannerData.length > 0">
                    <div class="carousel-inner" ref="carouselInner">
                        <div
                            v-for="(image, index) in bannerData"
                            :key="index"
                            class="carousel-item"
                            :class="{ active: currentIndex === index }"
                            @touchstart="touchStart"
                            @touchmove="touchMove"
                            @touchend="touchEnd"
                            @mousedown="mouseDown"
                            @mousemove="mouseMove"
                            @mouseup="mouseUp"
                        >
                            <img
                                :src="`${URL_IMAGE}${image.image}`"
                                :alt="image.title"
                                class="carousel-image"
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div class="carousel-btn">
                        <button class="btn-order" @click="toggleDisplay">Commander</button>
                    </div>
                    <div class="carousel-dots">
                        <div
                            v-for="(image, index) in bannerData"
                            :key="image.title"
                            class="dot-container"
                        >
                            <div class="dot-white"></div>
                            <span
                                class="dot"
                                :class="{ active: currentIndex === index }"
                                @click="((currentIndex = index), updateCarouselPosition())"
                            ></span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section-mobile-first" :class="{ show: isNavbarVisible }">
                <CatalogueCategory class="catalog-category" />
            </section>
        </div>
    </div>
</template>

<style scoped>
/*  */

.catalog-category {
    @apply mx-2 w-full;
}

.section {
    @apply hidden;
}

.section-mobile-first {
    @apply hidden;
}

.show {
    @apply flex justify-center;
}

/* carousel */
.carousel {
    @apply mt-16;
    position: relative;
    overflow: hidden;
}

.carousel-inner {
    display: flex;
    transition: 0.5s ease-in-out;
}

.carousel-item {
    flex: 0 0 100%;
}

.carousel-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.carousel-dots {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
}

.dot-container {
    position: relative;
    margin: 0 10px;
}

.dot-white {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #333;
    position: absolute;
    /* Assurez-vous qu'il est superposé au point blanc */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Centrer le point noir sur le blanc */
    z-index: 2;
    /* Positionner devant le point blanc */
}

.dot.active {
    background-color: gray;
}

.carousel-btn {
    @apply flex flex-col items-center absolute z-30 bottom-32 w-full;
}

.btn-order {
    @apply bg-lime-eronor py-3 px-8 rounded-full absolute z-30 mx-auto border-2 border-white;
    /* bottom: 4rem; */
}

@media screen and (min-width: 400px) {
    .carousel {
        @apply mt-8;
    }
}

@media screen and (min-width: 400px) {
    .carousel {
        @apply mt-0;
    }
}

@media screen and (min-width: 800px) {
    .carousel {
        @apply hidden;
    }

    .section-mobile-first {
        @apply flex justify-center;
    }

    .catalog-category {
        @apply mx-4;
    }
}

@media screen and (min-width: 1020px) {
    .catalog-category {
        @apply mx-8;
    }

    .section-mobile-first {
        @apply flex justify-center;
    }
}

@media screen and (min-width: 1200px) {
    /* .section-mobile-first {
    @apply w-[70rem];
  } */
}

@media screen and (min-width: 1500px) {
    .catalog-category {
        @apply mx-8;
    }
}
</style>
