import { productBus } from '@/event/product/productBus'
import { getSelectedProducts } from './getSelectedProducts'
import { getTotalPrice } from './getTotalPrice'
import { cartBus, indexUpdateElement } from '@/event/cartBus'
import { categoryIdBus } from '@/event/category/categoryIdBus'
import { getProductName } from './getProductName'
import { quantityBus } from '@/event/product/quantityBus'
import { priceBasketBus } from '@/event/product/priceBasketBus'
import { extraPricesBus } from '@/event/product/extraPricesBus'
import { addBasketAlert, alertWarning } from './alert'
import { addElementBasketBus } from '@/event/product/addElementBasketBus'
import { showBasketLogoBus } from '@/event/product/showBasketBus'
import { extractRelatedOptions } from './extractRelatedOptions'
import { selectionsBus } from '@/event/product/selectionsBus'
import { getProductTax } from './getProductTax'
import { categoryBus } from '@/event/category/categoryBus'

export const validateWarning = () => {
  const selectedProducts = getSelectedProducts()
  const totalPrice = getTotalPrice()
  const categorySortOrder = categoryBus.value?.find((category) => category.category_id === categoryIdBus.value)?.sort_order as number


  if (productBus.value) {
    if (indexUpdateElement.value !== null) {
      cartBus.value[indexUpdateElement.value] = {
        c_sort_order: categorySortOrder,
        category_id: categoryIdBus.value,
        product_image: productBus.value.image,
        product_id: productBus.value.product_id,
        product: getProductName(productBus.value),
        product_option: selectedProducts,
        price: Number(productBus.value.price),
        total_price: totalPrice,
        price_tax: getProductTax(totalPrice, productBus.value.rate),
        rate_tax: productBus.value.rate,
        quantity: quantityBus.value,
        lbl_printer_id: productBus.value.lbl_printer_id,
        printer_port: 9100,
        printer_id: productBus.value.printer_id
      }
      indexUpdateElement.value = null
    } else {
      cartBus.value.push({
        c_sort_order: categorySortOrder,
        category_id: categoryIdBus.value,
        product_image: productBus.value.image,
        product_id: productBus.value.product_id,
        product: getProductName(productBus.value),
        product_option: selectedProducts,
        price: Number(productBus.value.price),
        total_price: totalPrice,
        price_tax: getProductTax(totalPrice, productBus.value.rate),
        rate_tax: productBus.value.rate,
        quantity: quantityBus.value,
        lbl_printer_id: productBus.value.lbl_printer_id,
        printer_port: 9100,
        printer_id: productBus.value.printer_id
      })
    }
    priceBasketBus.value = totalPrice

    extraPricesBus.value = {}
    addBasketAlert.value = 'Ajouté au panier'
    addElementBasketBus.value = selectedProducts.map((product) => {
      return {
        optionName: product.optionName,
        productName: product.productName,
        sort_order: product.sort_order
      }
    })
    quantityBus.value = 1
    showBasketLogoBus.value = true
    alertWarning.value = ''
    extractRelatedOptions(productBus.value.options)
    selectionsBus.value = []

    setTimeout(() => {
      showBasketLogoBus.value = false
    }, 2000)

    setTimeout(() => {
      addBasketAlert.value = ''
    }, 4000)
  }
}
