import { ref } from 'vue'

export const addBasketAlert = ref<string>('')
export const alertMessage = ref<string>('')
export const alertElement = ref<string[]>([])
export const alertWarning = ref<string | null>(null)
export const alertWarningMessage = ref<string[]>([])

export const closeSuccess = () => {
  addBasketAlert.value = ''
}

export const closeAlert = () => {
  alertMessage.value = ''
  alertElement.value = []
}

export const closeWarning = () => {
  alertWarning.value = ''
  alertWarningMessage.value = []
}
