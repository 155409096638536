<script setup lang="ts">
const props = defineProps({ isActive: Boolean })
</script>

<template>
  <router-link to="/dashboard/password" class="link-password" :class="{ active: props.isActive }">
    <p>Mot de passe</p>
  </router-link>
</template>

<style scoped>
.link-password {
  @apply flex gap-2 p-2 border-b;
}

.link-password p {
  @apply text-sm ml-7;
}

.active {
  @apply bg-slate-hover text-lime-eronor;
}

.link-password:hover,
.link-password:hover p {
  @apply bg-slate-hover text-lime-eronor;
}
</style>
