<script setup lang="ts">
const props = defineProps({ isActive: Boolean })
</script>

<template>
  <router-link to="/dashboard/order" class="link-order" :class="{ active: props.isActive }">
    <p>Historique de commandes</p>
  </router-link>
</template>

<style scoped>
.link-order {
  @apply flex gap-2 p-2 border-b border-t;
}

.link-order p {
  @apply text-sm ml-7;
}

.active {
  @apply bg-slate-hover text-lime-eronor;
}

.link-order:hover,
.link-order:hover p {
  @apply bg-slate-hover text-lime-eronor;
}
</style>
