import { cartBus } from '@/event/cartBus'
import {
  updateFieldOrderData,
  type OrderDataProductsOptionType,
  type OrderDataProductsType
} from '@/event/order/data/orderData'
import { baseUrl } from '@/event/url/url'
import customer from '@/storage/customer'
import { printerDefault } from '@/storage/printerDefault'
import stores from '@/storage/stores'

export function submitOrder() {
  const productArray: OrderDataProductsType[] = []

  cartBus.value.forEach((product) => {
    const optionArray: OrderDataProductsOptionType[] = []
    product.product_option.forEach((option) => {
      optionArray.push({
        product_option_id: option.product_option_id,
        product_option_value_id: option.valueId,
        option_id: option.option_id,
        name: option.optionName,
        value: option.productName,
        type: option.type,
        price: String(option.price),
        title: option.title,
        po_sort_order: option.sort_order,
        paid: Number(option.price) > 0,
        reset: option.reset
      })
    })

    productArray.push({
      lbl_printer_id: product.lbl_printer_id,
      category_id: product.category_id,
      c_sort_order: product.c_sort_order,
      printer_port: 9100,
      printer_id: product.printer_id,
      product_id: product.product_id,
      name: product.product,
      quantity: product.quantity,
      price: (product.total_price / product.quantity).toFixed(2),
      total: String(Number(product.total_price).toFixed(2)),
      tax: String(product.price_tax.toFixed(2)),
      tax_rate: String(product.rate_tax),
      option: optionArray
    })
  })

  updateFieldOrderData({
    reference: customer.state.customerData.session_id,
    store_id: stores.state.storeData.store_id,
    store_url: baseUrl,
    store_name: stores.state.storeData.name,
    customer_id: customer.state.customerData.customer_id,
    customer_firstname: customer.state.customerData.firstname,
    customer_lastname: customer.state.customerData.lastname,
    customer_email: customer.state.customerData.email,
    customer_telephone: customer.state.customerData.telephone,
    shipping_firstname: customer.state.customerData.firstname,
    shipping_lastname: customer.state.customerData.lastname,
    printer_id: printerDefault.value?.printer_id,
    printer_port: printerDefault.value?.printer_port,
    printer_ip: printerDefault.value?.printer_ip,
    template_id: printerDefault.value?.template_id,
    products: productArray
  })
}
