import { computed } from 'vue'
import stores from '../stores'

export const configOpeningHours = computed(() => {
  const dataOpeningHours = stores.state.storeData.setting?.find(
    (s: { key: string }) => s.key == 'config_opening_hours'
  )

  return dataOpeningHours?.value || []
})
