import { reactive } from 'vue'

interface OpeningHours {
  opening_hours: [
    {
      days: string[]
      open: string
      close: string
    }
  ]
  pickup_service: [
    {
      days: string[]
      open: string
      close: string
    }
  ]
  delivery_service: [
    {
      days: string[]
      open: string
      close: string
    }
  ]
  table_reservation: [
    {
      days: string[]
      open: string
      close: string
    }
  ]
}

interface SettingData {
  config_address: string
  config_email: string
  config_telephone: string
  config_opening_hours: OpeningHours
  web_order: number
}

const state = reactive({
  settingData: {} as Partial<SettingData>
})

function setSettingData(data: Partial<SettingData>) {
  state.settingData = data
}

function getSettingData() {
  return state.settingData
}

export type { SettingData as SettingDataType }
export type { OpeningHours as OpeningHoursType }

export default {
  state,
  setSettingData,
  getSettingData
}
