<script setup lang="ts">
import { ref } from 'vue'
import ButtonFooter from '@/components/button/ButtonFooter.vue'

const props = defineProps({
    title: String,
    content: String
})

const isVisible = ref(false)

const toggleDisplay = () => {
    isVisible.value = !isVisible.value
}
</script>

<template>
    <div class="">
        <div>
            <h5 class="content-h5 text-uppercase">
                <p class="title">{{ title }}</p>
                <ButtonFooter :isVisible="isVisible" @click="toggleDisplay" />
            </h5>
            <div :class="['content-form', { show: isVisible }]">
                <div class="row-content">
                    <!-- <p>Aucunes informations</p> -->
                    <router-link :to="{ name: 'privacy-policy' }"
                        >Politique de confidentialité</router-link
                    >
                    <router-link :to="{ name: 'condition-use' }"
                        >Conditions générales d'utilisation</router-link
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.title {
    @apply w-full p-2;
}

a {
    @apply underline flex gap-2 fill-current;
}

a:hover .svg {
    @apply fill-limedark-eronor shadow-inner;
}

a:hover {
    @apply text-limedark-eronor;
}

.svg {
    @apply fill-lime-eronor w-5;
}

.svg-stroke {
    @apply stroke-lime-eronor w-5;
}

.row-content {
    @apply flex flex-col gap-2 flex-wrap;
}

.content-h5 {
    @apply relative flex justify-between items-center;
}

.text-uppercase {
    @apply uppercase font-semibold;
}

.content-form {
    @apply p-2;
    display: none;
    transition: 1s ease-in-out;
}

.content-form.show {
    @apply flex flex-col gap-3;
}

@media screen and (min-width: 800px) {
    .content-form {
        @apply flex flex-col p-2 flex-wrap;
        transition: 1s ease-in-out;
    }
}
</style>
