<script setup lang="ts">
const props = defineProps({ isActive: Boolean })
</script>

<template>
  <router-link to="/dashboard/edit" class="link-modify" :class="{ active: props.isActive }">
    <p>Modifier mes informations</p>
  </router-link>
</template>

<style scoped>
.link-modify {
  @apply flex gap-2 p-2 border-b border-t;
}

.link-modify p {
  @apply text-sm ml-7;
}

.active {
  @apply bg-slate-hover text-lime-eronor;
}

.link-modify:hover,
.link-modify:hover p {
  @apply bg-slate-hover text-lime-eronor;
}
</style>
