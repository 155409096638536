import { extraPricesBus } from '@/event/product/extraPricesBus'
import { relatedGroupBus } from '@/event/product/relatedGroupeBus'
import { selectionsBus } from '@/event/product/selectionsBus'
import { extractRelatedOptions } from './extractRelatedOptions'
import { productBus } from '@/event/product/productBus'
import { quantityBus } from '@/event/product/quantityBus'
import { indexUpdateElement } from '@/event/cartBus'

export const clearSelection = () => {
  selectionsBus.value = []
  extraPricesBus.value = {}
  relatedGroupBus.value = []
  if (indexUpdateElement.value === null) {
    quantityBus.value = 1
  }
  if (productBus.value) extractRelatedOptions(productBus.value.options)
}
