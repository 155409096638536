import { languageIdBus } from '@/event/languageIdBus'
import { getOptionName } from '@/utils/product/getOptionName'
import { ref, type Ref } from 'vue'
const totalVisibleOptions = ref()

export const buttonNextOption = (options: any[], showNextButton: Ref<boolean>) => {
  let lastVisibleOptionIndex = -1
  totalVisibleOptions.value = 0

  options.forEach((element: any, index: number) => {
    const optionName = getOptionName(element, languageIdBus.value)
    const optionDivId = `div-${optionName}`
    const optionElement = document.getElementById(optionDivId)

    if (!optionElement) {
      return
    }

    totalVisibleOptions.value++
    const rect = optionElement.getBoundingClientRect()
    const elementHeight = rect.height
    const isMoreThanHalfVisible =
      rect.top + elementHeight / 2 < window.innerHeight && rect.bottom - elementHeight / 2 > 0

    if (isMoreThanHalfVisible) {
      lastVisibleOptionIndex = index
    }
  })
  const basketButton = document.getElementById('button-basket')
  const isBasketButtonVisible =
    basketButton &&
    basketButton.getBoundingClientRect().top < window.innerHeight &&
    basketButton.getBoundingClientRect().bottom > 0

  if (isBasketButtonVisible) {
    showNextButton.value = false
    return
  }

  showNextButton.value = true
}

export const goToNextOptionFunction = (options: any[]) => {
  let currentOptionIndex = -1

  options.forEach((element: any, index: number) => {
    const optionName = getOptionName(element, languageIdBus.value)
    const optionDivId = `div-${optionName}`
    const optionElement = document.getElementById(optionName)
    const optionDivElement = document.getElementById(optionDivId)

    if (!optionElement || !optionDivElement) {
      return
    }

    const rect = optionDivElement.getBoundingClientRect()
    const elementHeight = rect.height

    const isHalfVisible =
      (rect.top >= 0 &&
        rect.top < window.innerHeight &&
        rect.bottom - rect.top >= elementHeight / 2) ||
      (rect.bottom <= window.innerHeight &&
        rect.bottom > 0 &&
        rect.bottom - rect.top >= elementHeight / 2)
    if (isHalfVisible) {
      currentOptionIndex = index
    }
  })

  if (currentOptionIndex === -1) {
    currentOptionIndex = 0
  }

  if (currentOptionIndex < totalVisibleOptions.value - 1) {
    let nextOptionIndex = currentOptionIndex + 1

    while (nextOptionIndex < options.length) {
      const nextOption = options[nextOptionIndex]
      const nextOptionName = getOptionName(nextOption, languageIdBus.value)
      const nextOptionElement = document.getElementById(nextOptionName)

      if (nextOptionElement) {
        nextOptionElement.scrollIntoView({ behavior: 'smooth' })
        break
      }
      nextOptionIndex++
    }
  }

  if (currentOptionIndex === 0) {
    const firstOptionName = getOptionName(options[0], languageIdBus.value)
    const firstOptionElement = document.getElementById(firstOptionName)

    if (firstOptionElement) {
      firstOptionElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  if (currentOptionIndex >= totalVisibleOptions.value - 1) {
    const basketButton = document.getElementById('button-basket')
    if (basketButton) basketButton.scrollIntoView({ behavior: 'smooth' })
  }
}
