<script setup lang="ts">
import {  ref, watch, type Ref } from 'vue'
import ButtonFooter from '@/components/button/ButtonFooter.vue'
import { useDaysFormatter } from '@/utils/main/footer/useDaysFormatter'
import stores from '@/storage/stores'

const props = defineProps({
    title: String
})
const isVisible = ref(false)
const services = ref<Record<string, any>[]>([])

watch(
    () => stores.state.storeData,
    () => {
        const data: any = stores.state.storeData.setting
        const openingHoursConfig = data.find(
            (setting: { key: string }) => setting.key === 'config_opening_hours'
        )

        if (openingHoursConfig) {
            const configValue = openingHoursConfig.value

            if (configValue.opening_hours.length > 0) {
                services.value.push({
                    key: 'openingHours',
                    value: configValue.opening_hours
                })
            }
            if (configValue.pickup_service.length > 0) {
                services.value.push({
                    key: 'pickupService',
                    value: configValue.pickup_service
                })
            }
            if (configValue.delivery_service.length > 0) {
                services.value.push({
                    key: 'deliveryService',
                    value: configValue.delivery_service
                })
            }
            if (configValue.table_reservation.length > 0) {
                services.value.push({
                    key: 'tableReservation',
                    value: configValue.table_reservation
                })
            }
        }
    }
)

const toggleDisplay = () => {
    isVisible.value = !isVisible.value
}

const { formatDays } = useDaysFormatter()

const getServiceName = (key: string) => {
    const names = {
        openingHours: "Heures d'ouverture",
        pickupService: 'Heures de retrait',
        deliveryService: 'Heures de livraison',
        tableReservation: 'Heures de réservation de table'
    }
    return names[key as keyof typeof names] || ''
}
</script>

<template>
    <div class="div-section-hours-main">
        <div>
            <h5 class="content-h5 text-uppercase">
                <p class="title">{{ title }}</p>
                <ButtonFooter :isVisible="isVisible" @click="toggleDisplay" />
            </h5>
            <div v-if="services.length === 0" class="px-2 py-1 flex justify-center">
                <p>Aucunes informations</p>
            </div>
            <div
                class="content-form"
                :class="{ show: isVisible }"
                v-for="(serviceObj, index) in services"
                :key="index"
            >
                <div class="row-content">
                    <svg
                        v-if="serviceObj.key === 'openingHours'"
                        class="svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM15.8321 14.5547C15.5257 15.0142 14.9048 15.1384 14.4453 14.8321L11.8451 13.0986C11.3171 12.7466 11 12.1541 11 11.5196L11 11.5L11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7L13 11.4648L15.5547 13.1679C16.0142 13.4743 16.1384 14.0952 15.8321 14.5547Z"
                        />
                    </svg>
                    <svg
                        v-if="serviceObj.key === 'pickupService'"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        class="svg"
                    >
                        <path
                            d="M665.6 870.4c0 14.08-11.52 25.6-25.6 25.6H256c-14.08 0-25.6-11.52-25.6-25.6V281.6c0-14.08 11.52-25.6 25.6-25.6h384c14.08 0 25.6 11.52 25.6 25.6v588.8z"
                            fill=""
                        />
                        <path
                            d="M819.2 870.4c0 14.08-11.52 25.6-25.6 25.6H665.6V256h128c14.08 0 25.6 11.52 25.6 25.6v588.8z"
                            fill-opacity="0%"
                            fill=""
                        />
                        <path
                            d="M793.6 908.8H256c-21.76 0-38.4-16.64-38.4-38.4V281.6c0-21.76 16.64-38.4 38.4-38.4h537.6c21.76 0 38.4 16.64 38.4 38.4v588.8c0 21.76-16.64 38.4-38.4 38.4zM256 268.8c-7.68 0-12.8 5.12-12.8 12.8v588.8c0 7.68 5.12 12.8 12.8 12.8h537.6c7.68 0 12.8-5.12 12.8-12.8V281.6c0-7.68-5.12-12.8-12.8-12.8H256z"
                            fill=""
                        />
                        <path
                            d="M640 908.8H256c-21.76 0-38.4-16.64-38.4-38.4V281.6c0-21.76 16.64-38.4 38.4-38.4h384c21.76 0 38.4 16.64 38.4 38.4v588.8c0 21.76-16.64 38.4-38.4 38.4zM256 268.8c-7.68 0-12.8 5.12-12.8 12.8v588.8c0 7.68 5.12 12.8 12.8 12.8h384c7.68 0 12.8-5.12 12.8-12.8V281.6c0-7.68-5.12-12.8-12.8-12.8H256zM729.6 256h25.6v550.4h-25.6z"
                            fill=""
                        />
                        <path
                            d="M661.76 904.96l-17.92-17.92 99.84-99.84 85.76 99.84-20.48 17.92-67.84-79.36zM614.4 243.2h-25.6c0-71.68-57.6-129.28-128-129.28S332.8 171.52 332.8 243.2h-25.6c0-85.76 69.12-154.88 153.6-154.88S614.4 157.44 614.4 243.2z"
                            fill=""
                        />
                        <path
                            d="M742.4 243.2h-25.6c0-71.68-57.6-129.28-128-129.28S460.8 171.52 460.8 243.2h-25.6c0-85.76 69.12-154.88 153.6-154.88S742.4 157.44 742.4 243.2z"
                            fill=""
                        />
                    </svg>
                    <svg
                        v-if="serviceObj.key === 'deliveryService'"
                        class="svg"
                        viewBox="0 0 512 512"
                    >
                        <g>
                            <path
                                class=""
                                d="M311.069,130.515c-0.963-5.641-5.851-9.768-11.578-9.768H35.43c-7.61,0-13.772,6.169-13.772,13.765   c0,7.61,6.162,13.772,13.772,13.772h64.263c7.61,0,13.772,6.17,13.772,13.773c0,7.603-6.162,13.772-13.772,13.772H13.772   C6.169,175.829,0,181.998,0,189.601c0,7.603,6.169,13.764,13.772,13.764h117.114c6.72,0,12.172,5.46,12.172,12.18   c0,6.72-5.452,12.172-12.172,12.172H68.665c-7.61,0-13.772,6.17-13.772,13.773c0,7.602,6.162,13.772,13.772,13.772h45.857   c6.726,0,12.179,5.452,12.179,12.172c0,6.719-5.453,12.172-12.179,12.172H51.215c-7.61,0-13.772,6.169-13.772,13.772   c0,7.603,6.162,13.772,13.772,13.772h87.014l5.488,31.042h31.52c-1.854,4.504-2.911,9.421-2.911,14.598   c0,21.245,17.218,38.464,38.464,38.464c21.237,0,38.456-17.219,38.456-38.464c0-5.177-1.057-10.094-2.911-14.598h100.04   L311.069,130.515z M227.342,352.789c0,9.146-7.407,16.553-16.553,16.553c-9.152,0-16.56-7.407-16.56-16.553   c0-6.364,3.627-11.824,8.892-14.598h15.329C223.714,340.965,227.342,346.424,227.342,352.789z"
                            />
                            <path
                                class=""
                                d="M511.598,314.072l-15.799-77.941l-57.689-88.759H333.074l32.534,190.819h38.42   c-1.846,4.504-2.904,9.421-2.904,14.598c0,21.245,17.219,38.464,38.456,38.464c21.246,0,38.464-17.219,38.464-38.464   c0-5.177-1.057-10.094-2.91-14.598h16.741c6.039,0,11.759-2.708,15.582-7.386C511.273,326.136,512.8,319.988,511.598,314.072z    M392.529,182.882h26.314l34.162,52.547h-51.512L392.529,182.882z M456.14,352.789c0,9.146-7.407,16.553-16.56,16.553   c-9.138,0-16.552-7.407-16.552-16.553c0-6.364,3.635-11.824,8.892-14.598h15.329C452.513,340.965,456.14,346.424,456.14,352.789z"
                            />
                        </g>
                    </svg>
                    <svg
                        v-if="serviceObj.key === 'tableReservation'"
                        xml:space="preserve"
                        class="svg"
                        viewBox="0 0 512 512"
                    >
                        <g>
                            <polygon
                                class="st0"
                                points="407.165,84.97 104.835,84.97 0,175.997 512,175.997  "
                            />
                            <rect y="183.102" class="st0" width="512" height="31.793" />
                            <rect y="222.223" class="st0" width="51.203" height="204.806" />
                            <rect
                                x="92.448"
                                y="222.223"
                                class="st0"
                                width="42.67"
                                height="130.918"
                            />
                            <rect
                                x="460.793"
                                y="222.223"
                                class="st0"
                                width="51.207"
                                height="204.806"
                            />
                            <rect
                                x="376.882"
                                y="222.223"
                                class="st0"
                                width="42.67"
                                height="130.918"
                            />
                        </g>
                    </svg>
                    <h3>{{ getServiceName(serviceObj.key) }}</h3>
                </div>
                <div v-for="(ouverture, idx) in serviceObj.value" :key="idx">
                    <p>
                        {{ formatDays(ouverture.days) }} : {{ ouverture.open }} -
                        {{ ouverture.close }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.div-section-hours-main {
    @apply flex flex-col w-full;
}

.title {
    @apply w-full p-2;
}

.svg {
    @apply fill-lime-eronor w-5;
}

.row-content {
    @apply flex gap-2 flex-wrap;
}

.content-h5 {
    @apply relative flex justify-between items-center;
}

.text-uppercase {
    @apply uppercase font-semibold;
}

.content-form {
    @apply px-2 py-1;
    display: none;
    transition: 1s ease-in-out;
}

.content-form.show {
    @apply flex flex-col;
}

@media screen and (min-width: 800px) {
    .content-form {
        @apply flex flex-col p-2 flex-wrap;
        transition: 1s ease-in-out;
    }
    .div-section-hours-main {
        @apply items-end;
    }
}
</style>
