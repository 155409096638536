import { languageIdBus } from '@/event/languageIdBus'
import { getOptionName } from './getOptionName'
import { selectionsBus } from '@/event/product/selectionsBus'
import { extraPricesBus } from '@/event/product/extraPricesBus'
import { productBus } from '@/event/product/productBus'
import { getOptionNameForBasket } from './getOptionNameForBasket'

export const handleCheckboxClick = (value: any, option: any) => {
  const optionName = getOptionNameForBasket(option, languageIdBus.value)

  if (!selectionsBus.value[optionName]) {
    selectionsBus.value[optionName] = []
  }

  if (!extraPricesBus.value) extraPricesBus.value = {}
  if (!extraPricesBus.value[optionName]) {
    extraPricesBus.value[optionName] = {}
  }

  const resetIndex = selectionsBus.value[optionName].findIndex((valId: number) => {
    const val = option.values.find((v: any) => v.option_value_id === valId)
    return val && val.reset === 1
  })

  if (resetIndex !== -1) {
    const resetElement = option.values.find(
      (v: any) => v.option_value_id === selectionsBus.value[optionName][resetIndex]
    )

    if (resetElement.grup === value.grup) {
      selectionsBus.value[optionName].splice(resetIndex, 1)
    }
  }

  if (value.reset && productBus.value) {
    const valueReset = selectionsBus.value[optionName].filter((valId: number) => {
      const currentOption = option.values.find((v: any) => v.option_value_id === valId)
      return currentOption && currentOption.grup !== value.grup
    })
    selectionsBus.value[optionName] = [...valueReset, value.option_value_id]
    extraPricesBus.value[optionName] = {}
    return
  }

  const index = selectionsBus.value[optionName].indexOf(value.option_value_id)

  if (index === -1) {
    if (value.price_status === 'general') {
      let numberFree = 0

      const prices = Object.values(extraPricesBus.value[optionName])

      prices.forEach((price) => {
        if (String(price) === '0') {
          numberFree++
        }
      })

      if (numberFree < option.free_option_count) {
        selectionsBus.value[optionName].push(value.option_value_id)
        extraPricesBus.value[optionName][value.option_value_id] = 0
      } else if (
        selectionsBus.value[optionName].length < option.max_option_count ||
        (option.free_option_count == 0 && option.max_option_count === 0)
      ) {
        selectionsBus.value[optionName].push(value.option_value_id)
        extraPricesBus.value[optionName][value.option_value_id] = value.ov_price
      }
    } else if (value.price_status === 'special') {
      if (
        selectionsBus.value[optionName].length < option.max_option_count ||
        (option.free_option_count == 0 && option.max_option_count === 0)
      ) {
        selectionsBus.value[optionName].push(value.option_value_id)
        extraPricesBus.value[optionName][value.option_value_id] = value.ov_price
      }

      // if (!extraPricesBus.value[optionName][value.option_value_id]) {
      //   extraPricesBus.value[optionName][value.option_value_id] = 0
      // }
    } else if (value.price_status === 'one_is_free') {
      if (selectionsBus.value[optionName].length === 0) {
        selectionsBus.value[optionName].push(value.option_value_id)
        extraPricesBus.value[optionName][value.option_value_id] = 0
      } else if (
        selectionsBus.value[optionName].length < option.max_option_count ||
        (option.free_option_count == 0 && option.max_option_count === 0)
      ) {
        selectionsBus.value[optionName].push(value.option_value_id)
        extraPricesBus.value[optionName][value.option_value_id] = value.ov_price
      }
    }
  } else {
    selectionsBus.value[optionName].splice(index, 1)
    delete extraPricesBus.value[optionName][value.option_value_id]
  }
}
