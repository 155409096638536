import { genereateSessionIdOrder } from '@/utils/account/generateSessionIdOrder'
import { reactive, watch } from 'vue'
import stores from './stores'
import { formatStoreName } from '@/utils/store/formatStoreName'

interface Customer {
  customer_id: number
  store_id: number
  language_id: number
  firstname: string
  lastname: string
  email: string
  telephone: string
  address_id: number
  status: number
  session_id: string
}

const state = reactive({
  customerData: {} as Partial<Customer>
})

function clearCustomerData() {
  state.customerData = {}
}
function setCustomerData(data: Customer) {
  state.customerData = data
}

function getCustomerData() {
  return state.customerData
}

export function isCustomerDefined() {
  return !!state.customerData.customer_id
}


watch(
  () => state.customerData,
  (newValue, oldValue) => {
    const store = stores.getStoresData().name

    const storedSession = store
      ? localStorage.getItem(`${formatStoreName(store)}_customer_session`) : localStorage.getItem('customer_session')

    const today = new Date().toISOString().split('T')[0]

    if (newValue.customer_id) {
      if (!storedSession || !storedSession.includes(today)) {
        const sessionId = genereateSessionIdOrder()
        const referenceCustomer = `${today}_${sessionId}`

        const token = store
          ? localStorage.setItem(`${formatStoreName(store)}_customer_session`, referenceCustomer)
          : localStorage.setItem('customer_session', referenceCustomer)

        state.customerData.session_id = referenceCustomer
      } else {
        state.customerData.session_id = storedSession
      }
    }
  },
  { deep: true }
)

export type { Customer }

export default {
  state,
  clearCustomerData,
  setCustomerData,
  getCustomerData
}
