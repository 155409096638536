<script setup lang="ts">
import { loader } from '@/event/main/loader'
import { watch } from 'vue'

setTimeout(() => {
  loader.value = true
}, 3000)

watch(
  () => loader.value,
  () => {
    if (loader.value == false) {
      setTimeout(() => {
        loader.value = true
      }, 3000)
    }
  }
)
</script>

<template>
  <section class="relative m-12">
    <svg class="burger" viewBox="0 0 712 752" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="waveGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style="stop-color: #616161; stop-opacity: 1" />
          <stop offset="100%" style="stop-color: #4caf50; stop-opacity: 1" />
        </linearGradient>
      </defs>

      <path
        d="M46.6877 539.631C46.6877 539.631 40.6877 578.881 54.6252 613.506C66.2502 642.506 96.3752 680.693 154 709.818C211.625 738.943 277.813 749.193 375.5 751.193C473.188 753.256 573.563 730.443 621.75 688.131C670 645.756 686.875 610.131 685.5 571.443C684.125 532.756 663.563 518.068 663.563 518.068L46.6877 539.631Z"
        fill="#616161" />
      <path
        d="M74.6252 500.193L46.1252 528.693C46.1252 528.693 38.3752 566.818 81.3752 608.068C113.938 639.256 171.563 693.443 362.938 694.881C542.063 696.256 598.375 656.881 632.938 629.068C667.563 601.256 680.563 552.943 677.125 539.381C673.75 525.818 663.125 518.006 663.125 518.006L74.6252 500.193Z"
        fill="#616161" />
      <path
        d="M86.8127 540.881C86.8127 540.881 78.7502 554.568 81.3752 563.256C83.4377 570.068 87.5002 597.881 168.188 633.131C210.875 651.818 283.5 667.068 377.813 664.318C489.688 661.068 579.313 638.568 622.688 597.818C667.125 556.131 658.625 531.318 658.625 531.318L86.8127 540.881Z"
        fill="#B3B3B3" />
      <path
        d="M63.7502 445.881C63.7502 445.881 37.0002 473.131 32.5627 485.256C28.3752 496.693 28.9377 513.318 36.3127 526.756C46.2502 544.756 58.6252 546.631 73.9377 555.818C90.5002 565.693 116.688 596.506 181.125 610.068C245.563 623.631 330.375 629.756 375.813 629.756C421.25 629.756 481.625 629.068 537.938 610.068C594.25 591.068 658.688 553.756 673.625 520.506C688.563 487.256 674.313 467.568 674.313 467.568L641.75 445.881H63.7502Z"
        fill="#616161" />
      <path
        d="M48.1252 411.318C48.1252 411.318 27.3752 410.381 22.3752 441.818C18.7502 464.693 35.2502 479.131 35.2502 479.131C35.2502 479.131 26.5002 491.693 36.6252 507.631C46.1252 522.568 56.3127 525.943 77.3127 529.318C92.4377 531.756 100.375 527.256 120.063 538.131C144.75 551.756 155.313 576.131 189.25 583.568C223.188 591.006 261.188 584.256 262.5 578.818C263.813 573.381 215 553.068 126.813 503.506C97.0627 486.818 69.8127 464.131 62.3752 452.631C54.9377 441.131 48.1252 411.318 48.1252 411.318Z"
        fill="#B3B3B3" />
      <path
        d="M474.875 576.818C474.875 585.631 504.063 581.568 517.625 574.131C531.188 566.693 552.875 547.693 572.563 539.506C592.25 531.381 599.688 538.818 628.188 525.256C656.688 511.693 672.313 479.818 672.313 479.818C672.313 479.818 686.188 462.693 687.313 450.693C689.063 432.381 687.25 423.506 674.375 419.443C661.5 415.381 633.438 457.568 629.375 462.318C625.313 467.068 565.188 511.006 541.438 527.943C517.688 544.881 474.875 572.068 474.875 576.818Z"
        fill="#B3B3B3" />
      <path
        d="M74.6252 388.256C74.6252 388.256 40.6877 392.318 35.9377 406.568C31.1877 420.818 46.1252 441.818 85.4377 468.318C117.688 490.006 228.188 542.193 272 569.381C322.188 600.568 364.688 644.131 371.75 644.693C380.563 645.381 441.75 580.381 526.438 523.256C606.938 468.881 662.125 455.443 681.813 415.381C692.375 393.881 666.875 381.443 666.875 381.443L339.875 257.318L74.6252 388.256Z"
        fill="#B3B3B3" />
      <path
        d="M375.813 608.068C385.938 606.943 438.875 555.818 501.313 516.506C563.75 477.193 656 435.068 669.563 411.381C683.125 387.693 650.563 372.006 650.563 372.006L270.625 308.256L48.1252 395.756C48.1252 395.756 35.9377 399.818 44.0627 410.006C52.1877 420.193 65.0627 437.131 93.5627 454.756C128.188 476.193 217.063 511.756 273.313 542.256C329.688 572.756 363.625 609.381 375.813 608.068Z"
        fill="#616161" />
      <path
        d="M78.7502 405.881L90.5627 437.506C90.5627 437.506 175.563 523.193 344.625 526.568C512.875 529.943 607.188 449.881 607.188 449.881L628.688 395.881L78.7502 405.881Z"
        fill="#E9EBEF" />
      <path
        d="M300.375 458.131L287.063 479.443C287.063 479.443 315.5 496.506 323.688 499.443C335.438 503.631 347 502.131 364.313 496.756C381.625 491.443 453.5 462.818 453.5 462.818C453.5 462.818 512.938 463.693 550.25 464.381C583.313 465.006 616.438 453.131 633.75 433.131C651.063 413.131 677.938 370.256 677.938 370.256C677.938 370.256 691.938 366.943 701.25 356.256C710.563 345.568 711.25 336.256 711.25 336.256C711.25 336.256 708.563 318.256 698.625 313.631C688.625 308.943 243.25 281.693 243.25 281.693L64.8127 295.006L34.7502 356.943L133.938 441.506L273.75 449.506L300.375 458.131Z"
        fill="#616161" />
      <path
        d="M44.0627 302.381C34.0002 297.818 23.4377 298.381 18.7502 311.693C14.0627 325.006 15.5002 330.881 15.5002 330.881C15.5002 330.881 1.81269 333.756 0.125192 350.943C-1.87481 371.568 20.7502 378.881 20.7502 378.881C20.7502 378.881 21.8127 386.881 30.7502 394.881C40.9377 404.006 51.1252 403.068 51.1252 403.068C51.1252 403.068 78.0002 429.506 96.0002 440.131C114 450.756 139.938 458.131 165.938 457.443C191.938 456.756 200.563 444.818 219.875 451.443C239.188 458.068 290.375 485.006 311 485.443C343.625 486.131 414.188 435.506 430.188 436.193C446.188 436.881 518.75 448.193 557.375 448.193C596 448.193 610 436.881 618.625 425.568C627.25 414.256 641.938 385.631 637.25 384.943C632.563 384.256 601.938 409.568 580 407.568C558 405.568 538.75 392.943 511.438 392.943C484.125 392.943 351 410.256 342.313 414.256C333.625 418.256 296.375 446.881 289.063 445.568C281.75 444.256 256.438 422.943 247.813 418.256C239.188 413.568 148.375 410.568 135.313 412.943C125.938 414.631 112.688 416.943 112.688 416.943C112.688 416.943 114 396.318 102.688 384.318C91.3752 372.318 54.0627 351.693 54.0627 351.693C54.0627 351.693 58.6877 309.006 44.0627 302.381Z"
        fill="#B3B3B3" />
      <path
        d="M634.563 338.318C634.563 338.318 675.188 338.318 686.5 337.631C697.813 336.943 711.125 336.318 711.125 336.318C711.125 336.318 707.25 317.881 701.375 308.068C689.375 287.943 660.5 282.381 660.5 282.381L634.563 338.318Z"
        fill="#B3B3B3" />
      <path
        d="M34.7502 277.756L30.3127 297.381C30.3127 297.381 91.7502 406.193 367.875 410.318C529.5 412.756 622.375 363.693 663.375 323.568C687.938 299.506 688.75 276.131 688.75 276.131L34.7502 277.756Z"
        fill="#616161" />
      <path
        d="M20.7502 223.631C17.2502 278.006 30.5002 297.506 30.5002 297.506C30.5002 297.506 121.875 380.256 355.25 384.068C594.438 387.943 672.188 308.631 685.813 285.506C694.5 270.818 701.438 242.006 695.938 200.006C686.813 130.631 606.938 -0.994334 354.125 0.00566553C101.313 1.00567 25.8752 144.693 20.7502 223.631Z"
        fill="url(#paint0_radial_764_2811)" />
      <path
        d="M260.938 67.6932C262.875 78.3182 214.938 113.693 208 79.7557C201.5 47.8182 259.125 57.8807 260.938 67.6932Z"
        fill="white" />
      <path
        d="M278.063 134.256C280.563 140.818 329.688 150.256 324.438 122.568C318.938 93.7557 272.938 120.756 278.063 134.256Z"
        fill="white" />
      <path
        d="M321.125 58.2557C322.375 67.6932 379.625 78.6932 378.438 52.0682C377 19.1932 319.688 47.3182 321.125 58.2557Z"
        fill="white" />
      <path
        d="M226.688 156.131C227 158.256 212.938 169.318 198.563 173.631C185.938 177.443 173 175.818 171.563 160.131C170.188 145.006 180.938 139.506 193.813 140.818C208.625 142.256 226.063 152.068 226.688 156.131Z"
        fill="white" />
      <path
        d="M345.625 200.318C343.25 190.381 289.438 176.568 293.063 208.006C296.75 239.318 348.188 210.881 345.625 200.318Z"
        fill="white" />
      <path
        d="M396 128.756C393.375 125.693 381.063 126.256 366.063 130.193C355.25 133.068 347.25 145.256 354.75 156.818C363.5 170.318 378.188 164.631 387.563 153.193C395.188 143.943 398.938 132.193 396 128.756Z"
        fill="white" />
      <path
        d="M453.625 55.6932C451.938 53.8807 439.625 52.6307 429.563 57.5057C419.438 62.3807 410.688 74.0057 420.063 84.8807C429.563 95.8182 443.688 89.5057 450.375 77.9432C455.688 68.6307 456.188 58.4432 453.625 55.6932Z"
        fill="white" />
      <path
        d="M492.25 150.818C490.625 140.631 445.375 122.506 445.688 150.256C446 178.693 493.688 160.006 492.25 150.818Z"
        fill="white" />
      <path
        d="M554.813 100.568C553.313 92.7557 495.75 86.4432 505.563 115.506C515.75 145.818 556.625 110.068 554.813 100.568Z"
        fill="white" />
      <path
        d="M475.563 202.818C475.938 194.068 432.125 174.006 428.875 200.256C425.625 226.506 475.063 214.193 475.563 202.818Z"
        fill="white" />
      <defs>
        <radialGradient id="paint0_radial_764_2811" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
          gradientTransform="translate(352.87 146.589) rotate(89.6556) scale(538.238 503.36)">
          <stop offset="0.517" stop-color="#616161" />
          <stop offset="0.643" stop-color="#818181" />
          <stop offset="1" stop-color="#B3B3B3" />
        </radialGradient>
      </defs>
    </svg>

    <svg class="burger-wave" :class="{ 'animated-burger': loader === false }" viewBox="0 0 712 752"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.6877 539.631C46.6877 539.631 40.6877 578.881 54.6252 613.506C66.2502 642.506 96.3752 680.693 154 709.818C211.625 738.943 277.813 749.193 375.5 751.193C473.188 753.256 573.563 730.443 621.75 688.131C670 645.756 686.875 610.131 685.5 571.443C684.676 548.243 676.951 533.674 670.899 525.727C671.936 523.978 672.848 522.236 673.625 520.506C683.886 497.665 680.374 481.224 677.17 473.169C674.479 477.144 672.313 479.818 672.313 479.818L677.17 473.169C681.288 467.084 686.632 457.951 687.313 450.693C688.849 434.622 687.64 425.819 678.619 421.159C679.77 419.293 680.835 417.37 681.813 415.381C690.147 398.417 676.03 387.095 669.702 383.072C674.68 375.453 677.938 370.256 677.938 370.256C677.938 370.256 691.938 366.943 701.25 356.256C710.563 345.568 711.25 336.256 711.25 336.256C711.25 336.256 707.25 317.881 701.375 308.068C697.234 301.123 691.083 295.912 684.802 292.066C686.471 287.792 687.437 284.151 687.995 281.432C695.595 265.798 700.968 238.417 695.938 200.006C686.813 130.631 606.938 -0.994334 354.125 0.00566553C101.313 1.00567 25.8752 144.693 20.7502 223.631C17.2502 278.006 30.5002 297.506 30.5002 297.506L31.872 299.879C26.3259 300.423 21.5156 303.839 18.7502 311.693C14.0627 325.006 15.5002 330.881 15.5002 330.881C15.5002 330.881 1.81269 333.756 0.125192 350.943C-1.87481 371.568 20.7502 378.881 20.7502 378.881C20.7502 378.881 21.8127 386.881 30.7502 394.881C33.8938 397.696 37.0373 399.554 39.8816 400.777C38.0442 402.488 36.6566 404.411 35.9377 406.568C34.9877 409.418 34.8252 412.538 35.4876 415.918C30.1471 419.966 24.6422 427.564 22.3752 441.818C18.7502 464.693 35.2502 479.131 35.2502 479.131C35.2502 479.131 33.9759 480.96 32.9626 484.109L32.5627 485.256L32.5566 485.272C28.3757 496.709 28.9413 513.325 36.3127 526.756C39.2127 532.009 42.3203 535.888 45.648 538.977L46.6877 539.631Z"
        fill-opacity="0.45" />
    </svg>
  </section>
</template>

<style scoped>
.burger {
  @apply w-20;
}

.burger-wave {
  @apply w-20 fill-amber-eronor absolute top-0;
}

.animated-burger {
  animation: waveAnimation 3s ease-in-out infinite;
}

@keyframes waveAnimation {
  0% {
    clip-path: inset(100% 0 0 0);
  }

  100% {
    clip-path: inset(0% 0 0 0);
  }
}

@media screen and (min-width: 800px) {
  .burger {
    @apply w-32;
  }

  .burger-wave {
    @apply w-32;
  }
}
</style>
