//src\utils\main\home\useCarousel.ts
import { ref, onMounted, watch } from 'vue'
import stores from '@/storage/stores'

export function useCarousel(timeInterval: number = 4000) {
  const currentIndex = ref(0)
  const carouselInner = ref<HTMLElement | null>(null)
  const bannerData = ref<{ banner_image_id: number; title: string; image: string }[]>([])
  let carouselInterval: ReturnType<typeof setInterval> | null = null
  let startX = 0
  let endX = 0
  let isDragging = false

  const formatTime = (num: number) => (num < 10 ? '0' + num : num)

  const addCarouselListeners = () => {
    if (carouselInner.value) {
      carouselInner.value.addEventListener('touchstart', touchStart)
      carouselInner.value.addEventListener('touchmove', touchMove)
      carouselInner.value.addEventListener('touchend', touchEnd)
      carouselInner.value.addEventListener('mousedown', mouseDown)
      carouselInner.value.addEventListener('mousemove', mouseMove)
      carouselInner.value.addEventListener('mouseup', mouseUp)
    }
  }

  const updateCarouselPosition = () => {
    if (carouselInner.value) {
      const offset = -currentIndex.value * carouselInner.value.clientWidth
      carouselInner.value.style.transform = `translateX(${offset}px)`
      carouselInner.value.style.transition = 'transform 0.5s ease-in-out'
    }
  }

  const nextSlide = () => {
    currentIndex.value = (currentIndex.value + 1) % bannerData.value.length
    updateCarouselPosition()
  }

  const previousSlide = () => {
    currentIndex.value =
      (currentIndex.value - 1 + bannerData.value.length) % bannerData.value.length
    updateCarouselPosition()
  }

  const startCarousel = () => {
    if (carouselInterval) clearInterval(carouselInterval)
    carouselInterval = setInterval(nextSlide, timeInterval)
  }

  const touchStart = (event: TouchEvent) => {
    startX = event.touches[0].clientX
    clearInterval(carouselInterval!)
    isDragging = true
  }

  const touchMove = (event: TouchEvent) => {
    if (!isDragging) return
    endX = event.touches[0].clientX
  }

  const touchEnd = () => {
    if (isDragging) {
      if (startX - endX > 50) {
        nextSlide()
      } else if (endX - startX > 50) {
        previousSlide()
      }
      startCarousel()
      isDragging = false
    }
  }

  const mouseDown = (event: MouseEvent) => {
    startX = event.clientX
    clearInterval(carouselInterval!)
    isDragging = true
  }

  const mouseMove = (event: MouseEvent) => {
    if (!isDragging) return
    endX = event.clientX
  }

  const mouseUp = () => {
    if (isDragging) {
      if (startX - endX > 50) {
        nextSlide()
      } else if (endX - startX > 50) {
        previousSlide()
      }
      startCarousel()
      isDragging = false
    }
  }

  watch(
    () => stores.state.storeData,
    (newVal) => {
      if (newVal.banner_images && newVal.banner_images.length > 0) {
        bannerData.value = newVal.banner_images
        startCarousel()
      }
    }
  )

  watch(
    () => bannerData.value,
    (newVal) => {
      if (newVal && newVal.length > 0) {
        startCarousel()
      }
    }
  )

  onMounted(() => {
    if (
      stores.state.storeData &&
      stores.state.storeData.banner_images &&
      stores.state.storeData.banner_images.length > 0
    ) {
      bannerData.value = stores.state.storeData.banner_images
      startCarousel()
    }
    addCarouselListeners()
  })

  return {
    currentIndex,
    bannerData,
    carouselInner,
    nextSlide,
    previousSlide,
    startCarousel,
    updateCarouselPosition,
    touchStart,
    touchMove,
    touchEnd,
    mouseDown,
    mouseMove,
    mouseUp
  }
}
