import { reactive } from 'vue'

export interface Product {
  category_id: number
  options: Option[]
  image: string
  lbl_printer_id: number
  points: number
  price: string
  printer_id: number
  product_id: number
  shipping: number
  show_in_suggested: boolean
  sort_order: number
  rate: number
  description: { language_id: number; name: string; description: string }[]
  related_ids: number[]
}

export interface Option {
  values: ValueOption[]
  option_id: number
  type: string
  free_option_count: number
  max_option_count: number
  required: boolean
  language: any[]
  po_sort_order: number
  title: string
  product_option_id: number
}

export interface ValueOption {
  image: string
  language: Language[]
  option_value_id: number
  ov_price: any
  price_status: string
  related_option_id: number[]
  reset: number
  sort_order: number
}

export interface Language {
  language_id: number
  name: string
}

const state = reactive({
  productData: [] as Product[]
})

function setProductData(data: Product[]) {
  state.productData = data
}

function getProductData() {
  return state.productData
}

export default {
  state,
  setProductData,
  getProductData
}
