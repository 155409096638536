import { computed } from 'vue'
import stores from '../stores'

export const configCheckoutRestaurant = computed(() => {
  const dataConfigCheckoutRestaurant = stores.state.storeData.setting?.find(
    (s: { key: string }) => s.key == 'config_checkout_restaurant'
  )

  return dataConfigCheckoutRestaurant?.value || 0
})
