<script setup lang="ts">
import ButtonClose from '@/components/button/ButtonClose.vue'
import ButtonSubstract from '@/components/button/basket/ButtonSubstract.vue'
import ButtonUpdate from '@/components/button/ButtonUpdate.vue'
import InputQuantityIndex from '@/components/form/InputQuantityIndex.vue'
import IconBasket from '@/components/icons/iconEronor/IconBasket.vue'
// import Ariane from '@/components/ariane/Ariane.vue'
import { URL_IMAGE } from '@/config/constants'
import { cartBus, type CartItem } from '@/event/cartBus'
import { showValidateOrder } from '@/event/order/showValidateOrder'
import { convertPriceToEuro } from '@/utils/price/convertPrice'
import { totalPrice } from '@/utils/price/totalPrice'
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import ButtonContinue from '@/components/button/ButtonContinueOrder.vue'
import BasketTax from '@/components/basket/BasketTax.vue'
import { numberBasket } from '@/event/basketBus'
import { toggleValidateOrder } from '@/utils/toggle/basket/toggleValidateOrder'
import { useProducts } from '@/utils/basket/useProducts'
import { useCart } from '@/utils/basket/useCart'
import ButtonClearBasketPage from '@/components/button/basket/ButtonClearBasketPage.vue'
import ButtonValidateOrderPage from '@/components/button/order/ButtonValidateOrderPage.vue'
import { waitAuhenticated } from '@/storage/account/waitAuthenticated'

const ButtonLogin = defineAsyncComponent(
    () => import('@/components/button/account/ButtonLogin.vue')
)
const ButtonLinkRegister = defineAsyncComponent(
    () => import('@/components/button/account/ButtonLinkRegister.vue')
)
const ButtonBack = defineAsyncComponent(() => import('@/components/button/ButtonBack.vue'))
const Ariane = defineAsyncComponent(() => import('@/components/ariane/Ariane.vue'))

const router = useRouter()

const { getGoodProduct, quantity, isHovered, updateParentQuantity, mapCartToProducts } = useCart()
const { product, getProduct } = useProducts()

watch(
    () => cartBus.value,
    () => {
        mapCartToProducts()
    },
    { deep: true }
)
</script>

<template>
    <section class="section-basketview">
        <section class="w-full flex flex-col items-center">
            <Ariane />

            <!-- <ButtonBack /> -->
            <section v-if="showValidateOrder" class="section-validate-order">
                <div class="validate-order">
                    <div class="absolute top-4 right-3">
                        <ButtonClose :function="toggleValidateOrder" />
                    </div>
                    <IconBasket />
                    <p class="text-center">Vous devez être connecté pour commander</p>
                    <div class="div-button-register">
                        <ButtonLinkRegister @click="toggleValidateOrder" />
                        <ButtonLogin @click="toggleValidateOrder" />
                    </div>
                </div>
            </section>

            <div class="div-product-backset">
                <p class="text-quantity">Nombre total de produit : {{ numberBasket }}</p>
                <p class="text-total">
                    Prix total du panier : {{ convertPriceToEuro(totalPrice) }} €
                </p>
            </div>

            <section class="section-content-basketview">
                <div class="div-basketview">
                    <div class="title-basket">
                        <IconBasket />
                        <h3>Mon panier</h3>
                    </div>
                    <section v-if="getGoodProduct.length > 0" class="section-product">
                        <div v-for="(e, index) in getGoodProduct" :key="index" class="">
                            <div class="element">
                                <div class="div-img">
                                    <img class="img" :src="`${URL_IMAGE}${e.image}`" alt="" />
                                </div>

                                <div class="w-full mt-2 relative">
                                    <p class="text-lg font-bold underline">{{ e.product }}</p>
                                    <p class="details-order" v-if="e.options.length > 0">
                                        Détails de la commande
                                        <svg
                                            viewBox="0 0 500 500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            @mouseover="isHovered[index] = true"
                                            @mouseleave="isHovered[index] = false"
                                            class="svg-info"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M250 500C388.071 500 500 388.071 500 250C500 111.929 388.071 0 250 0C111.929 0 0 111.929 0 250C0 388.071 111.929 500 250 500ZM250 465C368.741 465 465 368.741 465 250C465 131.259 368.741 35 250 35C131.259 35 35 131.259 35 250C35 368.741 131.259 465 250 465Z"
                                            />
                                            <path
                                                d="M250 124C261.046 124 270 132.954 270 144C270 155.046 261.046 164 250 164C238.954 164 230 155.046 230 144C230 132.954 238.954 124 250 124Z"
                                            />
                                            <path
                                                d="M230 202.334C230 191.288 238.954 182.334 250 182.334C261.046 182.334 270 191.288 270 202.334V356.334C270 367.38 261.046 376.334 250 376.334C238.954 376.334 230 367.38 230 356.334V202.334Z"
                                            />
                                        </svg>
                                    </p>
                                    <div v-if="isHovered[index]" class="div-info-order">
                                        <div
                                            v-for="(category, ind) in e.options"
                                            :key="ind"
                                            class="flex flex-wrap gap-1"
                                        >
                                            <p class="text-category">{{ category.optionName }} :</p>
                                            <div
                                                class="flex gap-2"
                                                v-for="(product, i) in category.products"
                                                :key="i"
                                            >
                                                <p>
                                                    {{ product.productName
                                                    }}<span
                                                        v-if="product.price !== 0"
                                                        class="span-price-option"
                                                        >{{ ' (' + product.price + '€)' }}</span
                                                    >
                                                    <span v-if="i < category.products.length - 1"
                                                        >,</span
                                                    >
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <InputQuantityIndex
                                        @update:quantity="updateParentQuantity"
                                        :index="index"
                                        :quantity="e.quantity"
                                    />
                                </div>

                                <div class="div-modify">
                                    <p class="font-bold text-nowrap mt-2">{{ e.price + ' €' }}</p>
                                    <div class="relative flex gap-2 items-center pt-4">
                                        <ButtonUpdate
                                            :product_id="e.product_id"
                                            :category_id="e.category_id"
                                            :index="index"
                                        />
                                        <ButtonSubstract :index="index" />
                                    </div>
                                    <!-- <div class="relative"></div> -->
                                </div>
                            </div>
                            <div class="border-b"></div>
                        </div>
                    </section>
                    <section v-else class="mt-5 mb-5">
                        <p>Votre panier est vide</p>
                    </section>
                </div>

                <div>
                    <BasketTax :totalPrice="totalPrice" />
                </div>
            </section>
        </section>

        <div class="div-button-validate">
            <ButtonContinue />
            <ButtonClearBasketPage v-if="cartBus.length !== 0" />
            <ButtonValidateOrderPage
                v-if="cartBus.length !== 0"
                @click="toggleValidateOrder"
                :disabled="waitAuhenticated == 0"
            />
        </div>
    </section>
</template>

<style scoped>
.text-quantity {
    @apply text-center pt-1;
}

.text-total {
    @apply text-amber-5-eronor text-center font-bold underline py-2;
}

.section-validate-order {
    @apply fixed flex justify-center mx-2 z-40;
}

.validate-order {
    @apply relative flex flex-col items-center justify-center gap-3 bg-slate-eronor p-3 min-w-72 rounded-lg;
}

.div-button-register {
    @apply flex w-full justify-between p-1;
}

.div-button-validate {
    @apply flex flex-col-reverse gap-2 mt-4;
}

/* .div-validate-order {
  @apply h-10;
} */

.div-modify {
    @apply gap-2 h-full;
}

/** */

.details-order {
    @apply flex flex-col text-sm my-1;
}

.svg-info {
    @apply fill-white w-6 hover:fill-lime-eronor;
}

/* .svg-info-content {
  @apply fill-white;
} */

.svg-threepoint {
    @apply w-6 fill-white-eronor hover:fill-lime-eronor;
}

.text-category {
    @apply text-lime-eronor;
}

.span-price-option {
    @apply text-red-700 font-semibold;
}

/** product */

.div-info-order {
    @apply bg-slate-hover p-2 rounded-lg -left-20 -right-16 absolute z-10;
}

.element {
    @apply flex justify-between py-2 relative;
}

.section-product {
    @apply w-full;
}

.div-img {
    @apply flex items-center;
}

.img {
    @apply w-40 max-h-[6rem] object-contain;
}

/** section */
.section-basketview {
    @apply relative w-full min-h-[32rem] flex flex-col items-center justify-between px-3;
}

.title-basket {
    @apply flex items-center gap-2 border-b w-full justify-center pb-2 font-semibold;
}

.title-basket svg {
    @apply w-7;
}

.div-basketview {
    @apply bg-slate-eronor w-full flex flex-col items-center p-3 rounded-lg;
}

@media screen and (min-width: 425px) {
    .details-order {
        @apply flex flex-row gap-2 text-base mr-1;
    }
}

@media screen and (min-width: 550px) {
    .div-button-validate {
        @apply flex-row justify-between w-full;
    }

    .div-info-order {
        @apply left-auto right-auto w-96;
    }
}

@media screen and (min-width: 600px) {
    .div-product-backset {
        @apply flex items-center justify-around w-full;
    }
}

@media screen and (min-width: 800px) {
    .section-basketview {
        @apply max-w-[70rem];
    }

    .div-basketview {
        @apply max-w-[70rem];
    }
}

@media screen and (min-width: 1000px) {
    .section-content-basketview {
        @apply flex gap-2 w-full;
    }
}
</style>
