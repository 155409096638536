<script setup lang="ts">
import { showNavAccount } from '@/event/account/alert/showNavAccount'
import { defineAsyncComponent } from 'vue';

const ButtonLogin = defineAsyncComponent(() => import('@/components/button/account/ButtonLogin.vue'));
const ButtonLinkRegister = defineAsyncComponent(() => import('@/components/button/account/ButtonLinkRegister.vue'));
</script>

<template>
  <section v-if="showNavAccount" class="section-show-account">
    <div>
      <h3>Mon compte</h3>
      <div class="div-show-button">
        <ButtonLinkRegister />
        <ButtonLogin />
      </div>
    </div>
  </section>
</template>

<style scoped>
.section-show-account {
  @apply absolute -right-16 top-10 bg-slate-eronor w-72 min-h-20 z-30 rounded-md p-2;
  @apply smoke:bg-black smoke:border smoke-white:bg-white smoke-white:border smoke-white:border-black
}

.div-show-button {
  @apply py-2 flex gap-2;
}
</style>
