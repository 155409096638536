<script setup lang="ts">
import Alert from '@/components/alert/Alert.vue'
import AlertSelectDeleteCustomer from '@/components/alert/AlertSelectDeleteCustomer.vue'
import ButtonDeleteAccount from '@/components/button/account/ButtonDeleteAccount.vue'
import ButtonHistoryOrder from '@/components/button/dashboard/ButtonHistoryOrder.vue'
import ButtonModifyAccount from '@/components/button/dashboard/ButtonModifyAccount.vue'
import ButtonModifyAddress from '@/components/button/dashboard/ButtonModifyAddress.vue'
import ButtonModifyPassword from '@/components/button/dashboard/ButtonModifyPassword.vue'
import ButtonPayment from '@/components/button/dashboard/ButtonPayment.vue'
import ButtonTransaction from '@/components/button/dashboard/ButtonTransaction.vue'
// import Ariane from '@/components/ariane/Ariane.vue'
import SideBarDashboard from '@/components/dashboard/SideBarDashboard.vue'
import { messageDeleteAccount } from '@/event/account/alert/messageDeleteAccount'
import { toggleAlertDeleteAccount } from '@/event/account/alert/toggle/toggleAlertDeleteAccount'
import { showSelectDeleteCustomer } from '@/event/account/delete/showSelectDeleteCustomer'
import { defineAsyncComponent } from 'vue'
const Ariane = defineAsyncComponent(() => import('@/components/ariane/Ariane.vue'))
</script>

<template>
  <section class="section-dashboard">
    <Ariane />

    <section class="w-full flex flex-col items-center justify-center relative">
      <Alert :message="messageDeleteAccount" :function="toggleAlertDeleteAccount" />
      <AlertSelectDeleteCustomer v-if="showSelectDeleteCustomer" />
    </section>

    <section class="section-content-dashboard">
      <div class="main-content">
        <h3 class="text-h3-account">Mon compte</h3>
        <section class="section-operation">
          <ButtonModifyAccount />
          <ButtonModifyPassword />
          <ButtonModifyAddress />
          <ButtonDeleteAccount />
        </section>
        <h3 class="text-h3-order">Mes commandes</h3>
        <section class="section-operation">
          <ButtonHistoryOrder />
          <!-- <ButtonTransaction /> -->
          <!-- <ButtonPayment /> -->
        </section>
      </div>

      <SideBarDashboard />
    </section>
  </section>
</template>

<style>
.section-dashboard {
  @apply px-3 w-full pb-6 relative;
}

.text-h3-account {
  @apply text-lg font-semibold pb-4;
}

.section-content-dashboard {
  @apply flex flex-col;
}

.main-content {
  @apply w-full;
}

.section-operation {
  @apply flex flex-col w-full items-center gap-4;
}

.text-h3-order {
  @apply text-lg font-semibold py-4;
}

/** aside */
.sidebar {
  display: none;
}

@media (min-width: 700px) {
  .section-content-dashboard {
    @apply flex-row justify-between gap-4;
  }
}

@media screen and (min-width: 900px) {
  .section-dashboard {
    @apply w-[55rem];
  }

  .section-operation {
    @apply grid grid-cols-2;
  }
}

@media screen and (min-width: 1200px) {
  .section-dashboard {
    @apply w-[70rem];
  }
}
</style>
