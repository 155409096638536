import { isAuthenticated } from '@/event/account/auth/authentification'
import { showValidateOrderModal } from '@/event/basket/showValidateOrderModal'
import router from '@/router'
import { linkGoOrder } from '@/event/basket/linkGoOrder'
import { showCartBus } from '@/event/cartBus'

export const toggleValidateOrderModal = () => {
  if (isAuthenticated.value) {
    showCartBus.value = false
    showValidateOrderModal.value = false
    router.push({ path: '/basket/order' })
  } else {
    linkGoOrder.value = true
    showValidateOrderModal.value = !showValidateOrderModal.value
  }
}
