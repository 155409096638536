import { computed } from 'vue'
import stores from '../stores'

export const configFacebook = computed(() => {
  const setting = stores.state.storeData.setting?.find(
    (setting: { key: string }) => setting.key === 'config_facebook'
  )

  return setting?.value || ''
})
