<script setup lang="ts">
import {  ref } from 'vue'
import ButtonFooter from '@/components/button/ButtonFooter.vue'
import { configTelephone } from '@/storage/setting/configTelephone'
import { configEmail } from '@/storage/setting/configEmail'
import { mapUrl, openMap } from '@/storage/mapUrl'
import InstagramLogo from '../icons/InstagramLogo.vue'
import FacebookLogo from '../icons/FacebookLogo.vue'
import { configAddress } from '@/storage/setting/configAddress'
import { configFacebook } from '@/storage/setting/configFacebook'
import { configInstagram } from '@/storage/setting/configInstagram'

const props = defineProps({
    title: String,
    nameStore: String
})
const isVisible = ref(false)
const toggleDisplay = () => {
    isVisible.value = !isVisible.value
}
</script>

<template>
    <div v-if="props.nameStore" class="contact">
        <h5 class="content-h5 text-uppercase">
            <p class="title">{{ title }}</p>

            <ButtonFooter :isVisible="isVisible" @click="toggleDisplay" />
        </h5>
        <div :class="['content-form', { show: isVisible }]">
            <div class="row-content">
                <svg viewBox="0 0 800 744" class="svg" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M672.627 425.905C676.257 428.748 678.378 433.103 678.378 437.715V728.659C678.378 736.943 671.663 743.659 663.378 743.659H496.752C488.468 743.659 481.752 736.943 481.752 728.659V562.911C481.752 554.626 475.036 547.911 466.752 547.911H333.254C324.969 547.911 318.254 554.626 318.254 562.911V728.659C318.254 736.943 311.538 743.659 303.254 743.659H136.627C128.343 743.659 121.627 736.943 121.627 728.659V437.715C121.627 433.103 123.748 428.748 127.379 425.905L390.755 219.652C396.187 215.398 403.82 215.398 409.252 219.652L672.627 425.905Z"
                        fill=""
                    />
                    <path
                        d="M7.97584 362.725C3.48676 356.233 4.79676 347.373 10.9724 342.458L390.659 40.2628C396.126 35.9113 403.874 35.9113 409.341 40.2628L789.028 342.458C795.203 347.373 796.513 356.233 792.024 362.725L762.578 405.31C757.641 412.45 747.692 413.921 740.899 408.515L409.341 144.625C403.874 140.274 396.126 140.274 390.659 144.625L59.1005 408.515C52.3082 413.921 42.359 412.45 37.4217 405.31L7.97584 362.725Z"
                        fill=""
                    />
                    <path
                        d="M672.271 0.34082C680.555 0.34082 687.271 7.05655 687.271 15.3408V172.968C687.271 185.53 672.752 192.527 662.925 184.701L594.05 129.845C590.477 126.999 588.396 122.68 588.396 118.111V15.3408C588.396 7.05655 595.111 0.34082 603.396 0.34082H672.271Z"
                        fill=""
                    />
                </svg>
                <p>{{ props.nameStore }}</p>
                <a :href="mapUrl" @click.prevent="openMap" target="_blank" v-if="configAddress"
                    >{{ configAddress }}
                </a>
                <div>
                    <div></div>
                </div>
            </div>

            <div class="row-content" v-if="configTelephone">
                <a :href="`tel:${configTelephone}`">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="svg"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M2.00589 4.54166C1.905 3.11236 3.11531 2 4.54522 2H7.60606C8.34006 2 9.00207 2.44226 9.28438 3.1212L10.5643 6.19946C10.8761 6.94932 10.6548 7.81544 10.0218 8.32292L9.22394 8.96254C8.86788 9.24798 8.74683 9.74018 8.95794 10.1448C10.0429 12.2241 11.6464 13.9888 13.5964 15.2667C14.008 15.5364 14.5517 15.4291 14.8588 15.0445L15.6902 14.003C16.1966 13.3687 17.0609 13.147 17.8092 13.4594L20.8811 14.742C21.5587 15.0249 22 15.6883 22 16.4238V19.5C22 20.9329 20.8489 22.0955 19.4226 21.9941C10.3021 21.3452 2.65247 13.7017 2.00589 4.54166Z"
                        />
                    </svg>
                    {{ configTelephone }}</a
                >
            </div>

            <div class="row-content" v-if="configEmail">
                <a :href="`mailto:${configEmail}`">
                    <svg
                        viewBox="0 0 800 600"
                        class="svg"
                        fill="hoverColor ? 'currentColor' : baseColor"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_338_3509)">
                            <path
                                d="M542.7 347.65C537.1 343.625 535.825 335.812 539.85 330.2C543.862 324.6 551.688 323.325 557.3 327.35L800 502.1V93.4375L407.462 385.038C405.237 386.688 402.625 387.5 400 387.5C397.375 387.5 394.763 386.688 392.538 385.038L0 93.4375V502.1L242.7 327.362C248.312 323.35 256.113 324.612 260.15 330.212C264.175 335.812 262.913 343.625 257.3 347.662L0 532.9V550C0 577.638 22.3625 600 50 600H750C777.638 600 800 577.638 800 550V532.9L542.7 347.65Z"
                            />
                            <path
                                d="M799 61.7846L400 358.179L1 61.7721V50C1 22.9148 22.9148 1 50 1H750C777.085 1 799 22.9148 799 50V61.7846Z"
                                stroke-width="2"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_338_3509">
                                <rect width="800" height="600" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    {{ configEmail }}</a
                >
            </div>

            <section class="flex gap-2 pt-3">
                <InstagramLogo v-if="configInstagram" />
                <FacebookLogo v-if="configFacebook" />
            </section>
        </div>
    </div>
</template>

<style scoped>
.contact {
    /* @apply max-w-96; */
    @apply w-full;
}

.title {
    @apply w-full p-2;
}

a {
    @apply underline flex gap-2 fill-current;
}

a:hover .svg {
    @apply fill-limedark-eronor shadow-inner;
}

a:hover {
    @apply text-limedark-eronor;
}

.svg {
    @apply fill-lime-eronor w-5;
}

.row-content {
    @apply flex gap-2 flex-wrap;
}

.content-h5 {
    @apply relative flex justify-between items-center;
}

.text-uppercase {
    @apply uppercase font-semibold;
}

.content-form {
    @apply p-2;
    display: none;
    transition: 1s ease-in-out;
}

.content-form.show {
    @apply flex flex-col gap-3;
}

@media screen and (min-width: 800px) {
    .content-form {
        @apply flex flex-col p-2 flex-wrap;
        transition: 1s ease-in-out;
    }
}
</style>
