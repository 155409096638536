<script setup lang="ts">
import { computed, nextTick, watch } from 'vue'
import { selectedOrder } from '@/event/account/dashboard/history/selectedOrder'
import { newVisibleOrder } from '@/event/order/data/newVisibleOrder'
import { visibleOrders } from '@/event/order/data/visibleOrders'
import { statusOrder } from '@/utils/order/statusOrder'

const getPaidClass = (order: any) => {
    return order.payment_status === 'Réglé' ? 'success' : 'error'
}

watch(
    () => newVisibleOrder.value?.order_status_id,
    (newStatus, oldStatus) => {
        if (newStatus !== oldStatus) {
            const updatedOrder = visibleOrders.value.find(
                (order) => order.order_id === newVisibleOrder.value?.order_id
            )
            if (updatedOrder) {
                selectedOrder.value = { ...updatedOrder }
            }
        }
    },
    { immediate: true }
)

const selectedOrderModal = computed(() => {
    let newSelectedOrder = selectedOrder.value

    if (newVisibleOrder.value) {
        newSelectedOrder = visibleOrders.value.find(
            (o) => o.order_id === selectedOrder.value?.order_id
        )
    }

    return newSelectedOrder
})

const getUniqueOptions = (options: any[]) => {
    const groupedOptions: Record<string, Set<string>> = {}

    options.forEach((option) => {
        if (!groupedOptions[option.name]) {
            groupedOptions[option.name] = new Set()
        }
        groupedOptions[option.name].add(option.value)
    })

    return Object.entries(groupedOptions).map(([name, values]) => ({
        name,
        values: Array.from(values)
    }))
}

function verifyIsPaid(paymentMethod: string, paymentStatus: string) {
    if (paymentStatus === 'Réglé' && paymentMethod === 'Non Payé') {
        return 'Payé'
    }
    return paymentMethod
}
</script>

<template>
    <section v-if="selectedOrderModal" class="section-modal-order">
        <section v-if="selectedOrderModal" class="flex flex-col gap-1">
            <div class="div-content-modal">
                <!-- {{ selectedOrder }} -->
                <p class="text-modal-order">Commande : {{ selectedOrderModal.invoice_no }}</p>
                <p class="text-modal-order">
                    Total :
                    <span class="text-lime-eronor"
                        >{{ selectedOrderModal.total.toFixed(2) }} €</span
                    >
                </p>
            </div>

            <div>
                <p>{{ selectedOrderModal.date_added }}</p>
                <p class="text-modal-order">Id° {{ selectedOrderModal.order_id }}</p>
            </div>

            <div class="div-content-modal">
                <p>
                    {{
                        verifyIsPaid(
                            selectedOrderModal.payment_method,
                            selectedOrderModal.payment_status
                        )
                    }}
                </p>
                <p>{{ selectedOrderModal.shipping_method }}</p>
            </div>

            <div class="div-content-modal">
                <div>
                    <p :class="statusOrder(selectedOrderModal.order_status_id).class">
                        {{ statusOrder(selectedOrderModal.order_status_id).text }}
                    </p>
                </div>
                <div>
                    <p :class="getPaidClass(selectedOrder)">
                        {{ selectedOrderModal.payment_status }}
                    </p>
                </div>
            </div>

            <section class="border-t mt-2 pt-2 flex flex-col gap-1">
                <div
                    v-for="(order, index) in selectedOrderModal.order_products"
                    :key="index"
                    class="pb-2"
                >
                    <div class="flex justify-between w-full">
                        <div class="flex gap-1">
                            <p class="font-semibold underline">{{ order.name }}</p>
                            <p>X {{ order.quantity }}</p>
                        </div>

                        <p class="text-lime-eronor">{{ parseFloat(order.total).toFixed(2) }} €</p>
                    </div>
                    <div v-for="(option, i) in getUniqueOptions(order.order_options)">
                        <div class="flex gap-1">
                            <p class="text-nowrap">{{ option.name }} :</p>
                            <div class="flex">
                                <p class="">
                                    <span v-for="(value, ind) in option.values" :key="ind">
                                        {{ value
                                        }}<span v-if="ind < option.values.length - 1">, </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </section>
</template>

<style scoped>
.section-modal-order {
    @apply flex flex-col gap-2 bg-slate-eronor z-20 min-w-[19rem] max-w-72 rounded-md mx-2 p-2 fixed max-h-[32rem] overflow-y-auto;
}

.div-content-modal {
    @apply flex justify-between;
}

.success {
    @apply bg-lime-eronor rounded-full py-1 px-2;
}

.error {
    @apply bg-red-eronor rounded-full py-1 px-2;
}

.wait-class {
    @apply bg-red-eronor px-2 p-1 rounded-full flex;
}

.approve-class {
    @apply bg-amber-eronor px-2 p-1 rounded-full flex;
}

.preparation-class {
    @apply bg-blue-500 px-2 p-1 rounded-full flex;
}

.ready-class {
    @apply bg-lime-eronor px-2 p-1 rounded-full flex;
}

.delivery-class {
    @apply bg-gray-500 px-2 p-1 rounded-full flex;
}

.text-modal-order {
    @apply flex gap-1 font-bold text-slate-300 text-lg;
}

@media screen and (min-width: 500px) {
    .section-modal-order {
        @apply max-w-96;
    }
}

@media screen and (min-width: 800px) {
    .section-modal-order {
        @apply max-w-[35rem];
    }
}
</style>
