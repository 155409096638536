import { reactive, ref } from 'vue'

interface Hours {
  days: string[]
  open: string
  close: string
}
interface OpeningHours {
  opening_hours: Hours[]
  pickup_service: Hours[]
  delivery_service: Hours[]
  table_reservation: Hours[]
}

interface Language {
  language_id: number
  name: string
}

interface Category {
  category_id: number
  column: number
  image: string
  language: Language[]
  show_in_suggested: boolean
  sort_order: number
  top: boolean
}

interface Printer {
  printer_id: number
  template_id: number
  name: string
  printer_type: string
  printer_ip: string
  printer_port: number
  print_delay: number
  printer_default: number
  copy_count: number
  sort_order: number
  store_id: number
  part_size: number
  buffer_size: string
}

interface PrivacyPolicy {
  privacy_policy_id: number
  information_id: number
  sort_order: number
  title: string
  content: string
  status: number
  date_modified: Date
}

interface ConditionUse {
  condtion_use_id: number
  information_id: number
  sort_order: number
  title: string
  content: string
  status: number
  date_modified: Date
}

interface StoresData {
  store_id: number
  partner_id: number
  name: string
  message_event: string
  banner_id: number
  banner_images: {
    banner_image_id: number
    title: string
    image: string
  }[]
  condition_use: ConditionUse[]
  privacy_policy: PrivacyPolicy[]
  printers: Printer[]
  setting: {
    [key: string]: any
  }
  category: Category[]
  status: number
  config: StoreConfig
}

type StoreConfig = {
  admin_pass: string
  bipper: number
  columnMode: number
  emporter_order: number
  fastprint: number
  hasAkead: number
  hasDB: number
  hasProductSort: number
  home_delivery_service: number
  kiosk_order: number
  store_close: string
  store_open: string
  sur_place_order: number
  table_reservation: number
  web_order: number
}

const state = reactive({
  storeData: {} as Partial<StoresData>
})

function setStoresData(data: any) {
  state.storeData = data
}

function getStoresData() {
  return state.storeData
}

export function getCurrentStoreId() {
  const storeId = state.storeData.store_id
  return storeId ?? 0
}

export type { StoresData as StoresDataType }
export type { OpeningHours as OpeningHoursType }
export type { Hours as HoursType }
export type { Category as CategoryType }

export default {
  state,
  setStoresData,
  getStoresData
}
