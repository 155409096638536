import { productBus } from '@/event/product/productBus'
import { convertFinalPrice } from '../price/convertFinalPrice'
import { convertPriceToEuro } from '../price/convertPrice'

export const getTotalPrice = (): number => {
  let totalPrice = 0

  if (productBus.value) {
    totalPrice = totalPrice + Number(convertFinalPrice(productBus.value.price))
  }
  return Number(convertPriceToEuro(totalPrice))
}
