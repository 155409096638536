<script setup lang="ts"></script>

<template>
  <router-link to="/dashboard/order" class="button-order"
    ><svg viewBox="-17 0 512 512.00429" id="acporderh" class="svg-order">
      <path
        d="m153.601562 119.46875h8.535157v8.535156c0 14.136719 11.460937 25.597656 25.597656 25.597656 14.140625 0 25.601563-11.460937 25.601563-25.597656v-8.535156h8.53125c14.140624 0 25.601562-11.460938 25.601562-25.601562 0-14.136719-11.460938-25.597657-25.601562-25.597657h-8.53125v-8.535156c0-14.136719-11.460938-25.597656-25.601563-25.597656-14.136719 0-25.597656 11.460937-25.597656 25.597656v8.535156h-8.535157c-14.136718 0-25.597656 11.460938-25.597656 25.597657 0 14.140624 11.460938 25.601562 25.597656 25.601562zm0-34.132812h17.066407c4.714843 0 8.535156-3.820313 8.535156-8.535157v-17.066406c0-4.710937 3.820313-8.53125 8.53125-8.53125 4.714844 0 8.535156 3.820313 8.535156 8.53125v17.066406c0 4.714844 3.820313 8.535157 8.53125 8.535157h17.066407c4.714843 0 8.535156 3.820312 8.535156 8.53125 0 4.714843-3.820313 8.535156-8.535156 8.535156h-17.066407c-4.710937 0-8.53125 3.820312-8.53125 8.53125v17.070312c0 4.710938-3.820312 8.53125-8.535156 8.53125-4.710937 0-8.53125-3.820312-8.53125-8.53125v-17.070312c0-4.710938-3.820313-8.53125-8.535156-8.53125h-17.066407c-4.710937 0-8.53125-3.820313-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125zm0 0"
      ></path>
      <path
        d="m332.800781 68.269531h-54.832031c-11.414062-40.386719-48.269531-68.269531-90.234375-68.269531s-78.820313 27.882812-90.230469 68.269531h-54.835937c-23.550781.027344-42.636719 19.113281-42.66406275 42.664063v358.402344c.02734375 23.550781 19.11328175 42.636718 42.66406275 42.667968h290.132812c23.554688-.03125 42.640625-19.117187 42.667969-42.667968v-358.402344c-.027344-23.550782-19.113281-42.636719-42.667969-42.664063zm-145.066406 119.464844c41.941406-.070313 78.757813-27.925781 90.234375-68.265625h46.300781v341.332031h-273.066406v-341.332031h46.300781c11.472656 40.339844 48.289063 68.195312 90.230469 68.265625zm0-170.664063c42.417969 0 76.800781 34.382813 76.800781 76.796876 0 42.417968-34.382812 76.800781-76.800781 76.800781-42.414063 0-76.800781-34.382813-76.800781-76.800781.050781-42.394532 34.40625-76.75 76.800781-76.796876zm170.667969 452.265626c0 14.136718-11.460938 25.597656-25.601563 25.597656h-290.132812c-14.136719 0-25.597657-11.460938-25.597657-25.597656v-358.402344c0-14.136719 11.460938-25.597656 25.597657-25.597656h51.636719c-.257813 2.816406-.4375 5.648437-.4375 8.53125 0 2.886718.179687 5.71875.4375 8.535156h-43.101563c-9.425781 0-17.066406 7.640625-17.066406 17.066406v341.332031c0 9.425781 7.640625 17.066407 17.066406 17.066407h273.066406c9.425781 0 17.066407-7.640626 17.066407-17.066407v-341.332031c0-9.425781-7.640626-17.066406-17.066407-17.066406h-43.101562c.253906-2.816406.433593-5.648438.433593-8.535156 0-2.882813-.179687-5.714844-.433593-8.53125h51.632812c14.140625 0 25.601563 11.460937 25.601563 25.597656zm0 0"
      ></path>
      <path
        d="m85.335938 273.070312c4.710937 0 8.53125-3.820312 8.53125-8.535156 0-4.710937-3.820313-8.53125-8.53125-8.53125v-34.136718h42.667968c4.710938 0 8.53125-3.820313 8.53125-8.53125 0-4.714844-3.820312-8.535157-8.53125-8.535157h-42.667968c-9.425782 0-17.066407 7.640625-17.066407 17.066407v34.136718c0 9.425782 7.640625 17.066406 17.066407 17.066406zm0 0"
      ></path>
      <path
        d="m116.96875 241.4375c-3.347656-3.234375-8.671875-3.1875-11.960938.101562-3.292968 3.292969-3.339843 8.613282-.105468 11.960938l17.066406 17.070312c3.332031 3.328126 8.734375 3.328126 12.066406 0l42.667969-42.667968c3.234375-3.347656 3.1875-8.671875-.105469-11.960938-3.292968-3.292968-8.613281-3.339844-11.960937-.105468l-36.632813 36.632812zm0 0"
      ></path>
      <path
        d="m290.136719 230.402344h8.53125c4.714843 0 8.535156-3.820313 8.535156-8.535156 0-4.710938-3.820313-8.53125-8.535156-8.53125h-8.53125c-4.714844 0-8.535157 3.820312-8.535157 8.53125 0 4.714843 3.820313 8.535156 8.535157 8.535156zm0 0"
      ></path>
      <path
        d="m213.335938 230.402344h42.667968c4.710938 0 8.53125-3.820313 8.53125-8.535156 0-4.710938-3.820312-8.53125-8.53125-8.53125h-42.667968c-4.714844 0-8.535157 3.820312-8.535157 8.53125 0 4.714843 3.820313 8.535156 8.535157 8.535156zm0 0"
      ></path>
      <path
        d="m213.335938 264.535156h68.265624c4.714844 0 8.535157-3.820312 8.535157-8.53125 0-4.714844-3.820313-8.535156-8.535157-8.535156h-68.265624c-4.714844 0-8.535157 3.820312-8.535157 8.535156 0 4.710938 3.820313 8.53125 8.535157 8.53125zm0 0"
      ></path>
      <path
        d="m85.335938 358.402344c4.710937 0 8.53125-3.820313 8.53125-8.535156 0-4.710938-3.820313-8.53125-8.53125-8.53125v-34.132813h42.667968c4.710938 0 8.53125-3.820313 8.53125-8.535156 0-4.710938-3.820312-8.53125-8.53125-8.53125h-42.667968c-9.425782 0-17.066407 7.640625-17.066407 17.066406v34.132813c0 9.425781 7.640625 17.066406 17.066407 17.066406zm0 0"
      ></path>
      <path
        d="m164.636719 301.167969-36.632813 36.632812-11.035156-11.03125c-3.347656-3.234375-8.671875-3.1875-11.960938.101563-3.292968 3.292968-3.339843 8.617187-.105468 11.964844l17.066406 17.066406c3.332031 3.332031 8.734375 3.332031 12.066406 0l42.667969-42.667969c3.234375-3.347656 3.1875-8.667969-.105469-11.960937-3.292968-3.292969-8.613281-3.339844-11.960937-.105469zm0 0"
      ></path>
      <path
        d="m298.667969 298.667969h-8.53125c-4.714844 0-8.535157 3.820312-8.535157 8.535156 0 4.710937 3.820313 8.53125 8.535157 8.53125h8.53125c4.714843 0 8.535156-3.820313 8.535156-8.53125 0-4.714844-3.820313-8.535156-8.535156-8.535156zm0 0"
      ></path>
      <path
        d="m213.335938 315.734375h42.667968c4.710938 0 8.53125-3.820313 8.53125-8.53125 0-4.714844-3.820312-8.535156-8.53125-8.535156h-42.667968c-4.714844 0-8.535157 3.820312-8.535157 8.535156 0 4.710937 3.820313 8.53125 8.535157 8.53125zm0 0"
      ></path>
      <path
        d="m213.335938 349.867188h68.265624c4.714844 0 8.535157-3.820313 8.535157-8.53125 0-4.714844-3.820313-8.535157-8.535157-8.535157h-68.265624c-4.714844 0-8.535157 3.820313-8.535157 8.535157 0 4.710937 3.820313 8.53125 8.535157 8.53125zm0 0"
      ></path>
      <path
        d="m85.335938 443.734375c4.710937 0 8.53125-3.820313 8.53125-8.53125 0-4.714844-3.820313-8.535156-8.53125-8.535156v-34.132813h42.667968c4.710938 0 8.53125-3.820312 8.53125-8.53125 0-4.714844-3.820312-8.535156-8.53125-8.535156h-42.667968c-9.425782 0-17.066407 7.640625-17.066407 17.066406v34.132813c0 9.425781 7.640625 17.066406 17.066407 17.066406zm0 0"
      ></path>
      <path
        d="m164.636719 386.503906-36.632813 36.632813-11.035156-11.035157c-3.347656-3.234374-8.671875-3.1875-11.960938.105469-3.292968 3.289063-3.339843 8.613281-.105468 11.960938l17.066406 17.066406c3.332031 3.332031 8.734375 3.332031 12.066406 0l42.667969-42.664063c3.234375-3.351562 3.1875-8.671874-.105469-11.964843-3.292968-3.289063-8.613281-3.335938-11.960937-.101563zm0 0"
      ></path>
      <path
        d="m298.667969 384.003906h-8.53125c-4.714844 0-8.535157 3.820313-8.535157 8.53125 0 4.714844 3.820313 8.535156 8.535157 8.535156h8.53125c4.714843 0 8.535156-3.820312 8.535156-8.535156 0-4.710937-3.820313-8.53125-8.535156-8.53125zm0 0"
      ></path>
      <path
        d="m213.335938 401.070312h42.667968c4.710938 0 8.53125-3.820312 8.53125-8.535156 0-4.710937-3.820312-8.53125-8.53125-8.53125h-42.667968c-4.714844 0-8.535157 3.820313-8.535157 8.53125 0 4.714844 3.820313 8.535156 8.535157 8.535156zm0 0"
      ></path>
      <path
        d="m213.335938 435.203125h68.265624c4.714844 0 8.535157-3.820313 8.535157-8.535156 0-4.710938-3.820313-8.53125-8.535157-8.53125h-68.265624c-4.714844 0-8.535157 3.820312-8.535157 8.53125 0 4.714843 3.820313 8.535156 8.535157 8.535156zm0 0"
      ></path>
      <path
        d="m455.683594 110.933594h-40.960938c-13.15625 1.023437-23.042968 12.433594-22.1875 25.601562v281.601563c0 .953125.160156 1.898437.476563 2.796875l27.792969 79.976562c1.71875 6.519532 7.601562 11.070313 14.339843 11.09375 6.742188.023438 12.65625-4.484375 14.417969-10.992187l27.828125-80.085938c.316406-.894531.476563-1.839843.476563-2.789062v-281.601563c.859374-13.167968-9.03125-24.578125-22.183594-25.601562zm-4.976563 334.789062c-10.167969-2.648437-20.847656-2.648437-31.019531 0l-6.621094-19.054687h44.269532zm-41.105469-232.386718v-25.601563h51.199219v25.601563zm34.132813 196.265624v-110.933593c0-4.710938-3.820313-8.53125-8.53125-8.53125-4.714844 0-8.535156 3.820312-8.535156 8.53125v110.933593h-17.066407v-179.199218h17.066407v34.132812c0 4.714844 3.820312 8.535156 8.535156 8.535156 4.710937 0 8.53125-3.820312 8.53125-8.535156v-34.132812h17.066406v179.199218zm-29.011719-281.597656h40.960938c2.421875 0 5.117187 3.652344 5.117187 8.53125v34.132813h-51.199219v-34.132813c0-4.878906 2.695313-8.53125 5.121094-8.53125zm20.480469 362.332032-9.898437-28.484376c6.527343-1.394531 13.277343-1.394531 19.804687 0zm0 0"
      ></path>
    </svg>
    <div><p class="text-order">Historique de commandes</p></div>
  </router-link>
</template>

<style scoped>
.button-order {
  @apply flex items-center w-full p-2 h-16 border gap-3;
}
.svg-order {
  @apply pr-2 w-10 fill-lime-eronor border-r;
}
.text-order {
  @apply font-semibold;
}
.button-order:hover,
.button-order:hover .svg-order,
.button-order:hover .text-order {
  @apply border-lime-eronor text-lime-eronor;
}
@media (min-width: 900px) {
  .button-order {
    @apply h-20;
  }
  .svg-order {
    @apply w-12;
  }
}
</style>
