import stores from '@/storage/stores'
import { formatStoreName } from '@/utils/store/formatStoreName'

const isExcludedRoute = (routeName: any) => {
  return [
    'register',
    'confirm-register',
    'login',
    'basket',
    'forget',
    'racine',
    'privacy-policy',
    'condition-use'
  ].includes(routeName)
}
const isExcludedRouteDesktop = (routeName: any) => {
  return ['register', 'confirm-register', 'login', 'basket', 'forget'].includes(routeName)
}

export const shouldShowSectionOrder = (routeName: any, cart: string | any[]) => {
  return !isExcludedRoute(routeName) && cart.length > 0
}
export const shouldShowSectionOrderDesktop = (routeName: any, cart: string | any[]) => {
  const store = stores.state.storeData.name
  let savedData: any
  if (store) {
    const storeName = formatStoreName(store)
    savedData = localStorage.getItem(`${storeName}_cart`)
  }
  const hasSavedData = savedData && savedData.length > 0
  return (
    (!isExcludedRouteDesktop(routeName) && cart.length > 0) ||
    (!isExcludedRouteDesktop(routeName) && hasSavedData)
  )
}
