import { watch } from 'vue'
import stores from '@/storage/stores'
import products from '@/storage/products'
import { useRoute } from 'vue-router'

export function useCatalogProductWatchers(
  catalogueBase: any,
  catalogProduct: any,
  categoryId: any,
  categoryNameBus: any,
  filterProductsByCategory: (categoryId: any) => void
) {
  const route = useRoute()

  watch(
    () => stores.state.storeData,
    (newValue) => {
      catalogueBase.value = newValue.category
    }
  )

  watch(
    () => products.state.productData,
    (newValue) => {
      catalogProduct.value = newValue
      filterProductsByCategory(categoryId.value)
    }
  )

  watch(
    () => route.params.category_id,
    (newCategoryId) => {
      categoryNameBus.value = route.params.category_name
      categoryId.value = newCategoryId
      filterProductsByCategory(newCategoryId)
    }
  )

  watch([() => catalogueBase.value, () => catalogProduct.value], () => {
    if (categoryId.value) {
      filterProductsByCategory(categoryId.value)
    }
  })
}
