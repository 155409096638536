// index.ts

import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/Page/HomeView.vue'
import ErrorLoadUrl from '@/views/error/ErrorLoadUrl.vue'
import ProductView from '@/views/Page/ProductView.vue'
import CatalogProductView from '@/views/Page/CatalogProductView.vue'
import BasketView from '@/views/Page/BasketView.vue'
import DashboardView from '@/views/Page/dashboard/DashboardView.vue'
import DashboardOrderView from '@/views/Page/dashboard/DashboardOrderView.vue'
import OrderView from '@/views/Page/OrderView.vue'
import { dataLoaded } from '@/event/dataLoaded'
import { linkGoOrder } from '@/event/basket/linkGoOrder'

const routes = [
  {
    path: '/:table?',
    name: 'racine',
    component: HomeView
  },
  {
    path: '/cproduct/:category_id/:category_name',
    name: 'catalog-product',
    component: CatalogProductView
  },
  { path: '/product/:category_id/:product_id', name: 'product', component: ProductView },
  { path: '/basket', name: 'basket', component: BasketView },
  { path: '/error_urlchecker', name: 'error_urlchecker', component: ErrorLoadUrl },
  { path: '/login', name: 'login', component: () => import('@/views/Page/account/LoginView.vue') },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Page/account/RegisterView.vue')
  },
  {
    path: '/confirm-register/:token',
    name: 'confirm-register',
    component: () => import('@/views/Page/account/ConfirmRegisterView.vue')
  },
  {
    path: '/forget/:token',
    name: 'forget',
    component: () => import('@/views/Page/account/ForgetView.vue')
  },
  { path: '/dashboard', name: 'dashboard', component: DashboardView },
  {
    path: '/dashboard/edit',
    name: 'dashboard-edit',
    component: () => import('@/views/Page/dashboard/DashboardEditView.vue')
  },
  {
    path: '/dashboard/password',
    name: 'dashboard-password',
    component: () => import('@/views/Page/dashboard/DashboardPasswordView.vue')
  },
  {
    path: '/dashboard/address',
    name: 'dashboard-address',
    component: () => import('@/views/Page/dashboard/DashboardAddressView.vue')
  },
  { path: '/dashboard/order', name: 'dashboard-order', component: DashboardOrderView },
  {
    path: '/dashboard/transaction',
    name: 'dashboard-transaction',
    component: () => import('@/views/Page/dashboard/DashboardTransactionView.vue')
  },
  {
    path: '/dashboard/recurring',
    name: 'dashboard-recurring',
    component: () => import('@/views/Page/dashboard/DashboardRecurringView.vue')
  },
  { path: '/basket/order', name: 'basket-order', component: OrderView },
  {
    path: '/success-payment/:order_id',
    name: 'success-payment',
    component: () => import('@/views/Page/order/SuccessView.vue')
  },
  {
    path: '/cancel-payment/:order_id',
    name: 'cancel-payment',
    component: () => import('@/views/Page/order/CancelView.vue')
  },

  {
    path: '/cancel-delete/:customer_id',
    name: 'cancel-delete',
    component: () => import('@/views/Page/CancelDeletedView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/Page/information/PrivacyPolicyView.vue')
  },
  {
    path: '/condition-use',
    name: 'condition-use',
    component: () => import('@/views/Page/information/ConditionUseView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: () => '/'
  }
]

const router = createRouter({
  // history: createMemoryHistory(),
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (dataLoaded.value == undefined && to.name === 'error_urlchecker') {
    next({ path: '/' })
  } else {
    next()
  }

  if (from.name == 'basket' && to.name == 'login') {
    linkGoOrder.value = true
  }
})

export default router
