export function getOrderTax(orderPrice: any, tax: any) {
  const price = Number(orderPrice)
  const taxRt = parseFloat(tax as string) / 100

  if (price === 0) {
    return { tax: 0, rate: 0 }
  }

  const finalTax = (price * taxRt) / (1 + taxRt)
  return { tax: finalTax, rate: taxRt * 100 }
}
