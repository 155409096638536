import { url } from "@/event/url/url"
import { arrayUrlTest } from "./constants"

export let urlWebSocket: any

const host = url.hostname

if (arrayUrlTest.includes(host)) {
    // test
    urlWebSocket = "https://api.eronor.eu:4000"
} else {
    // production
    urlWebSocket = "https://api.eronor.info:4000"
}