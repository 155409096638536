import { reference } from '@/event/cartBus'
import { ref } from 'vue'

export interface OrderDataType {
  reference: string
  store_id: number
  store_url: string
  store_name: string
  delivery_time: string | null
  order_time: string | null
  order_status: number
  table_no: string | null
  customer_id: number
  customer_firstname: string
  customer_lastname: string
  customer_email: string
  customer_telephone: string
  customer_delivery_time: string
  // payment_firstname: string
  // payment_lastname: string
  // payment_company: string
  // payment_address_1: string
  // payment_address_2: string
  // payment_city: string
  // payment_postcode: string
  // payment_country: string
  // payment_country_id: number
  // payment_zone: string
  // payment_zone_id: number
  // payment_address_format: string
  payment_method: string
  payment_code: string
  shipping_firstname: string
  shipping_lastname: string
  shipping_company: string | null
  shipping_address_1: string | null
  shipping_address_2: string | null
  shipping_city: string | null
  shipping_postcode: string | null
  // shipping_country: string
  shipping_city_id: number | null
  shipping_country_id: number | null
  // shipping_zone: string
  shipping_zone_id: number | null
  // shipping_address_format: string
  shipping_method: string
  shipping_code: string
  shipping_price: string
  comment: string | null
  total: string
  total_tax: string
  // affiliate_id: number
  commission: string
  marketing_id: number
  tracking: string
  language_id: number
  currency_id: number
  currency_code: string
  // currency_value: string
  ip: string
  forwared_ip: string
  user_agent: string
  accept_language: string
  printer_id: number
  printer_port: number
  printer_ip: string
  template_id: number
  token_captcha: string | null
  products: OrderDataProductsType[]
}

export interface OrderDataProductsType {
  lbl_printer_id: number
  printer_port: number
  // printer_ip: string
  printer_id: number
  product_id: number
  name: string
  // model: string
  quantity: number
  price: string
  total: string
  tax: string
  tax_rate: string
  category_id: number
  c_sort_order: number
  // reward: string
  option: OrderDataProductsOptionType[]
}

export interface OrderDataProductsOptionType {
  product_option_id: number
  product_option_value_id: number
  option_id: number
  name: string
  value: string
  type: string
  price: string
  title: string
  po_sort_order: number
  paid: boolean
  reset: number
}

export const orderDataEmpty = {
  reference: '',
  store_id: 0,
  store_url: '',
  store_name: '',
  delivery_time: null,
  order_time: null,
  order_status: 0,
  table_no: null,
  customer_id: 0,
  customer_firstname: '',
  customer_lastname: '',
  customer_email: '',
  customer_telephone: '',
  customer_delivery_time: '',
  // payment_firstname: '',
  // payment_lastname: '',
  // payment_company: '',
  // payment_address_1: '',
  // payment_address_2: '',
  // payment_city: '',
  // payment_postcode: '',
  // payment_country: '',
  // payment_country_id: 0,
  // payment_zone: '',
  // payment_zone_id: 0,
  // payment_address_format: '',
  payment_method: '',
  payment_code: '',
  shipping_firstname: '',
  shipping_lastname: '',
  shipping_company: null,
  shipping_address_1: null,
  shipping_address_2: null,
  shipping_city: null,
  shipping_postcode: null,
  // shipping_country: '',
  shipping_city_id: null,
  shipping_country_id: null,
  // shipping_zone: '',
  shipping_zone_id: null,
  // shipping_address_format: '',
  shipping_method: '',
  shipping_code: '',
  shipping_price: '',
  comment: null,
  total: '',
  total_tax: '',
  affiliate_id: 0,
  commission: '',
  marketing_id: 0,
  tracking: '',
  language_id: 3,
  currency_id: 3,
  currency_code: 'EUR',
  // currency_value: '',
  ip: '',
  forwared_ip: '',
  user_agent: '',
  accept_language: '',
  printer_id: 0,
  printer_port: 0,
  printer_ip: '',
  template_id: 0,
  token_captcha: null,
  products: [
    {
      lbl_printer_id: 0,
      printer_port: 0,
      printer_ip: '',
      printer_id: 0,
      product_id: 0,
      name: '',
      model: '',
      quantity: 0,
      price: '',
      total: '',
      tax: '',
      tax_rate: '',
      reward: '',
      category_id: 0,
      c_sort_order: 0,
      option: [
        {
          product_option_id: 0,
          product_option_value_id: 0,
          option_id: 0,
          name: '',
          value: '',
          type: '',
          price: '',
          title: '0',
          po_sort_order: 0,
          paid: true,
          reset: 0
        }
      ]
    }
  ]
}

export const orderData = ref<OrderDataType>(orderDataEmpty)

export function updateFieldOrderData<K extends keyof OrderDataType>(
  fields: Partial<OrderDataType>
) {
  Object.entries(fields).forEach(([key, value]) => {
    if (key in orderData.value) {
      const typedKey = key as keyof OrderDataType // Assurez-vous que key est une clé valide
        ; (orderData.value[typedKey] as any) = value // Force TypeScript à accepter l'assignation
    }
  })
}
export function addProduct(product: OrderDataProductsType) {
  orderData.value.products.push(product)
}

export function resetOrderData() {
  orderData.value = { ...orderDataEmpty }
}

export function resetProducts() {
  orderData.value.products = []
}
